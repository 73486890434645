import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FolderPlusIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import BreadCrumb from "../../../../../Component/BreadCrumb";
import PerformanceForm from "../Components/PerformanceForm";
import AppraisalAssessmentForm from "../Components/AppraisalAssessmentForm";

const AppraisalAssessmentPage = () => {
  let navigate = useNavigate();
  const { id, employee_id } = useParams();
  const [mode, setMode] = useState("create");
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("mode")) {
      setMode(params.get("mode"));
    }
  }, []);
  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <PencilSquareIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Appraisal Employee</p>
          <BreadCrumb
            list={[
              {
                href: "/client/demo/performance/appraisals",
                title: "Appraisal",
              },
              {
                href: "/client/demo/performance/appraisals/" + id,
                title: id,
              },
              {
                href: null,
                title: "Assessment",
              },
            ]}
          />
        </div>
      </div>

      <div className="report-list">
        <div className="card flex flex-col w-full">
          <AppraisalAssessmentForm appraisalId={id} employeeId={employee_id} mode={mode} />
        </div>
      </div>
    </div>
  );
};

export default AppraisalAssessmentPage;
