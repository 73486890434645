import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FolderPlusIcon } from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";
import BreadCrumb from "../../../../../Component/BreadCrumb";
import PerformanceForm from "../Components/PerformanceForm";

const CreateTemplatePage = () => {
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <FolderPlusIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">
            Create Appraisal Template
          </p>
          <BreadCrumb
            list={[
              {
                href: "/client/demo/performance/templates",
                title: "Appraisal Template",
              },
              {
                href: null,
                title: "Create",
              },
            ]}
          />
        </div>
      </div>

      <div className="report-list">
        <div className="card flex flex-col w-full">
          <PerformanceForm />
        </div>
      </div>
    </div>
  );
};

export default CreateTemplatePage;
