import axios from "axios";
import moment from "moment";

export const getUtilityCandidateInJobPosition = async (
  companyJobPositionId
) => {
  const response = await axios.get(
    `/api/client/utility/candidates-job-position/${companyJobPositionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const getUtilityAppraisalTemplates = async () => {
  const response = await axios.get(`/api/client/utility/appraisal-templates`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const getUtilityAllClients = async () => {
  const response = await axios.get(`/api/client/utility/all-clients`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
