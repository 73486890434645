import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import {
  getAppraisalEmployeeForm,
  postAppraisalEmployeeForm,
} from "../../../../../api";
import { ToastContainer, toast } from "react-toastify";
import BtnSpinner from "../../../../../Component/BtnSpinner";
import { inputIsOnlyNumeric } from "../../../../../helpers";
import PageSpinner from "../../../../../Component/PageSpinner";
import { useNavigate } from "react-router-dom";
const AppraisalAssessmentForm = ({
  appraisalId,
  employeeId,
  mode = "create",
}) => {
  const navigate = useNavigate();
  const [forms, setForms] = useState([
    {
      name: "",
      id: "",
      total_weight: "",
      total_target: "",
      indicators: [
        { id: "", name: "", weight: "", target: "", achieved_target: "" },
      ],
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [name, setName] = useState("");

  const handleIndicatorChange = (aspectIndex, indicatorIndex, field, value) => {
    const updatedAspects = [...forms];
    updatedAspects[aspectIndex].indicators[indicatorIndex][field] = value;
    setForms(updatedAspects);
  };

  const fetchAppraisalForm = async () => {
    setLoading(true);
    const result = await getAppraisalEmployeeForm(appraisalId, employeeId);
    setLoading(false);
    if (result.success) {
      // console.log(result);
      setForms(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const sanitizeForm = forms.map((aspect) => ({
      id: aspect.id,
      indicators: aspect.indicators.map((indicator) => ({
        id: indicator.id,
        achieved_target: indicator.achieved_target,
      })),
    }));
    const result = await postAppraisalEmployeeForm(
      appraisalId,
      employeeId,
      sanitizeForm
    );

    if (!result.success) {
      setSubmitting(false);
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          navigate(`/client/demo/performance/appraisals/${appraisalId}`);
        },
      });
    }
  };

  useEffect(() => {
    if (appraisalId && employeeId) {
      fetchAppraisalForm();
    }
  }, [appraisalId, employeeId]);

  return (
    <>
      <ToastContainer />
      {loading && <PageSpinner />}
      {!loading && (
        <form onSubmit={handleSubmit} className="w-full px-2 pt-2">
          <h2 className="text-xl font-bold mb-6">Appraisal Form</h2>
          {forms.map((aspect, aspectIndex) => (
            <div key={aspectIndex} className="mb-6 bg-gray-100 rounded-lg mt-3">
              <div className="flex items-center justify-between">
                <div className="p-4">
                  <p className="text-base font-semibold text-grey-98">
                    {aspect.name}
                  </p>
                </div>
              </div>
              <table className="w-full border-collapse border border-line-grey text-left">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="border border-line-grey px-4 py-2">
                      Indicator
                    </th>
                    <th className="border border-line-grey px-4 py-2">
                      Weight
                    </th>
                    <th className="border border-line-grey px-4 py-2">
                      Target
                    </th>
                    <th className="border border-line-grey px-4 py-2">
                      Realization (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {aspect.indicators.map((indicator, indicatorIndex) => (
                    <tr key={indicatorIndex} className="hover:bg-gray-100">
                      <td className="border border-line-grey px-4 py-2">
                        <input
                          type="text"
                          value={indicator.name}
                          disabled
                          onChange={(e) =>
                            handleIndicatorChange(
                              aspectIndex,
                              indicatorIndex,
                              "name",
                              e.target.value
                            )
                          }
                          placeholder="Indicator Name"
                          className="border-none w-full bg-transparent focus:outline-none disabled:cursor-not-allowed"
                        />
                      </td>
                      <td className="border border-line-grey px-4 py-2">
                        <input
                          type="text"
                          value={indicator.weight}
                          disabled
                          onChange={(e) =>
                            handleIndicatorChange(
                              aspectIndex,
                              indicatorIndex,
                              "weight",
                              e.target.value
                            )
                          }
                          placeholder="Weight"
                          className="border-none w-full bg-transparent focus:outline-none disabled:cursor-not-allowed"
                        />
                      </td>
                      <td className="border border-line-grey px-4 py-2">
                        <input
                          type="text"
                          value={indicator.target + "%"}
                          disabled
                          onChange={(e) =>
                            handleIndicatorChange(
                              aspectIndex,
                              indicatorIndex,
                              "target",
                              e.target.value
                            )
                          }
                          placeholder="Target (%)"
                          className="border-none w-full bg-transparent focus:outline-none disabled:cursor-not-allowed"
                        />
                      </td>
                      <td className="border border-line-grey px-4 py-2">
                        <input
                          type="text"
                          value={
                            mode === "readonly" ? indicator.achieved_target + "%" : indicator.achieved_target
                          }
                          disabled={mode === "readonly"}
                          onChange={(e) =>
                            inputIsOnlyNumeric(e.target.value, 0, 100) &&
                            handleIndicatorChange(
                              aspectIndex,
                              indicatorIndex,
                              "achieved_target",
                              e.target.value
                            )
                          }
                          placeholder="Realization (%)"
                          className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          <hr className="my-6" />
          <div className="flex h-[40px] gap-2 items-end justify-end ">
            {mode === "create" && (
              <>
                {!submitting && (
                  <button
                    className=" bg-main-violate-50 w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type=""
                  >
                    Save as Draft
                  </button>
                )}
              </>
            )}

            {mode != "readonly" && (
              <>
                {submitting ? (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type=""
                    disabled={true}
                  >
                    <BtnSpinner />
                  </button>
                ) : (
                  <button
                    className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                  >
                    {mode === "create" ? "Submit" : "Update"}
                  </button>
                )}
              </>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default AppraisalAssessmentForm;
