import React, { Component } from "react";
import { inputIsOnlyAlphabetic } from "../../../../../../../helpers";
// import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-select";
import config from "../../../../../../../config";

class CreateSession extends Component {
  constructor(props) {
    super(props);
    this.nameInputRef = React.createRef();
    this.handleNameInputFocus = this.handleNameInputFocus.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      selectedRole: null,
      selectedTemplate: 30,
      selectedAssessor: null,
    };
  }

  handleNameInputFocus() {
    this.nameInputRef.current.focus();
  }

  handleNameChange(e) {
    const { value } = e.target;
    if (inputIsOnlyAlphabetic(value)) this.props.onNameChange(value);
  }

  componentDidMount() {}

  render() {
    const minDate = new Date();
    minDate.setDate(
      minDate.getDate() + parseInt(config.CLIENT_JOB_MIN_DEADLINE)
    );
    const minDateString = minDate.toISOString().split("T")[0];
    const {
      onJobRoleChange,
      onTemplateChange,
      jobRoles,
      jobRole,
      assessor,
      assessors,
      templates,
      template,
      name,
      startDate,
      endDate,
      onStartDateChange,
      onEndDateChange,
      onAssessorChange,
    } = this.props;

    return (
      <div className="h-[420px]">
        <div className="card">
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Name / Goal
              </label>
              <input
                ref={this.nameInputRef}
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Type your appraisal name/goal"
                value={name}
                onChange={this.handleNameChange}
                required
              />
            </div>
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Assessor
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                value={assessor}
                options={assessors.map((option) => {
                  return {
                    value: option.id,
                    label: option.name,
                  };
                })}
                onChange={(e) => {
                  this.setState({ selectedAssessor: e });
                  onAssessorChange(e);
                }}
              />
            </div>
          </div>
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            <div className="mb-2 z-index-999">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Start Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="title"
                id="title"
                placeholder="Choose date..."
                value={startDate}
                onChange={(e) => {
                  onStartDateChange(e.target.value);
                }}
                required
                min={minDateString}
              />
            </div>
            <div className="mb-2 z-index-999">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                End Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="title"
                id="title"
                placeholder="Choose date..."
                value={endDate}
                onChange={(e) => {
                  onEndDateChange(e.target.value);
                }}
                required
                min={minDateString}
              />
            </div>
          </div>
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Template
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                value={template}
                options={templates.map((option) => {
                  return {
                    value: option.id,
                    label: option.name,
                  };
                })}
                onChange={(e) => {
                  this.setState({ selectedTemplate: e });
                  onTemplateChange(e);
                }}
              />
            </div>
            <div className="mb-2">
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Job Role
              </label>
              <Select
                className="py-0.5 rounded-lg mt-2 border border-line-grey"
                classNamePrefix={"select"}
                value={jobRole}
                options={jobRoles.map((option) => {
                  return {
                    value: option.id,
                    label: option.job_role,
                  };
                })}
                onChange={(e) => {
                  this.setState({ selectedRole: e });
                  onJobRoleChange(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateSession;
