import {
  BriefcaseIcon,
  CalendarDaysIcon,
  CheckIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  LockClosedIcon,
  ClipboardDocumentListIcon,
  VideoCameraIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";

const Stepper = ({ formPosition = 1 }) => {
  return (
    <div className="card mb-6">
      <div className="w-full py-6">
        <div className="flex">
          <div className="w-1/3">
            <div className="relative mb-2">
              <div className="w-12 h-12 mx-auto bg-violate-100 rounded-full text-lg text-white flex items-center px-2">
                {formPosition > 1 ? (
                  <>
                    <CheckIcon className="text-main-violate-600" />
                  </>
                ) : (
                  <>
                    <ClipboardDocumentListIcon className="text-main-violate-600" />
                  </>
                )}
              </div>
            </div>
            <div className="text-xs text-center md:text-base">
              Appraisal Info
            </div>
          </div>
          <div className="w-1/3">
            <div className="relative mb-2">
              <div
                className="absolute flex align-center items-center align-middle content-center"
                style={{
                  width: "calc(100% - 2.5rem - 1rem)",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className="w-0 bg-main-violate-100 py-1 rounded"
                    style={
                      formPosition > 1 ? { width: "100%" } : { width: "0%" }
                    }
                  ></div>
                </div>
              </div>
              <div
                className={
                  "w-12 h-12 mx-auto rounded-full text-lg text-white flex items-center px-2 bg-violate-100"
                }
              >
                {formPosition > 2 ? (
                  <>
                    <CheckIcon className="text-main-violate-600" />
                  </>
                ) : (
                  <>
                    <UserPlusIcon
                      className={
                        formPosition > 1
                          ? "text-main-violate-600"
                          : "text-grey-500"
                      }
                    />
                  </>
                )}
              </div>
            </div>
            <div className="text-xs text-center md:text-base">
              Select Employee
            </div>
          </div>
          <div className="w-1/3">
            <div className="relative mb-2">
              <div
                className="absolute flex align-center items-center align-middle content-center"
                style={{
                  width: "calc(100% - 2.5rem - 1rem)",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className="w-0 bg-main-violate-100 py-1 rounded"
                    style={
                      formPosition > 2 ? { width: "100%" } : { width: "0%" }
                    }
                  ></div>
                </div>
              </div>

              <div className="w-12 h-12 mx-auto bg-violate-100 rounded-full text-lg text-white flex items-center px-2">
                <DocumentMagnifyingGlassIcon
                  className={
                    formPosition > 2 ? "text-main-violate-600" : "text-grey-500"
                  }
                />
              </div>
            </div>

            <div className="text-xs text-center md:text-base">
              Preview Appraisal
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
