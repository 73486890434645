import axios from "axios";
import moment from "moment";

const countries = require("./data/countries.json");
const states = require("./data/states.json");
const cities = require("./data/cities.json");

const validationErrorMessages = {
  required: "This field is required.",
  email: "Please enter a valid email address.",
  password: "Password must be at least 8 characters long.",
  password_confirmation: "Password confirmation must match password.",
  name: "Name must contain only alphabetic characters.",
  phone:
    "Phone number must contain only numeric characters and minimum 9 digits.",
};

export function validationError(key) {
  return validationErrorMessages[key];
}

export function findStateById(id) {
  let state = states.find((state) => id == state.id);
  return state;
}
export function findCountryById(id) {
  let country = countries.find((country) => id == country.id);
  return country;
}
export function findCityById(id) {
  let city = cities.find((city) => id == city.id);
  return city;
}
export async function getEmploymentStatus(user) {
  let employments = await axios
    .get("/api/employment-status", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getIndustryFields(user) {
  let employments = await axios
    .get("/api/industry-fields", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getJobSkills(user) {
  let employments = await axios
    .get("/api/job-skills", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationDegrees(user) {
  let employments = await axios
    .get("/api/helper/education-degree-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationSkills(user) {
  let employments = await axios
    .get("/api/helper/education-skill-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationMajors(user) {
  let employments = await axios
    .get("/api/helper/education-major-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export function inputIsUrlFormat(string) {
  var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  if (urlPattern.test(string)) {
    return true;
  }

  return false;
}

export function inputIsOnlyAlphabetic(input) {
  if (input === "") return true;
  var regex = /^[a-zA-Z\s.]+$/;
  var valid = regex.test(input);
  if (!valid) {
    // alert("Name must contain only alphabetic characters.");
    return false;
  } else {
    return true;
  }
}

export function inputIsOnlyNumeric(input, min = null, max = null) {
  if (input === "") return true;
  var regex = /^[0-9]+$/;
  var valid = regex.test(input);
  if (!valid) {
    return false;
  } else {
    if (min && max) {
      if (input < min || input > max) {
        return false;
      }
    }
    return true;
  }
}

export function inputIsPhoneNumber(input) {
  if (input === "") return true;
  var regex = /^[0-9+]+$/; // Assumes a 10-digit phone number format
  var valid = regex.test(input);
  if (!valid) {
    // alert("Name must contain only alphabetic characters.");
    return false;
  } else {
    return true;
  }
}

export function inputIsPhoneNumberWithCode(input) {
  if (input === "") return true;

  var regex = /^\+62[0-9]+$/; // Assumes a phone number with '+62' country code format
  var valid = regex.test(input);

  if (!valid) {
    return false;
  } else {
    return true;
  }
}

export function inputIsMinimumAge(threshold = 17, input) {
  var selectedDate = moment(input, "YYYY-MM-DD");
  var currentDate = moment();
  var minimumAge = 17; // Minimum age in years
  var ageInYears = currentDate.diff(selectedDate, "years");
  if (ageInYears < minimumAge) {
    // alert("You must be at least 17 years old.");
    return false;
  } else {
    return true;
  }
}

export function inputIsUseranme(input) {
  var regex = /^[a-zA-Z0-9]+$/;
  var valid = regex.test(input);
  if (input === "") return true;
  if (!valid) {
    // alert("Username must contain only alphanumeric characters.");
    return false;
  } else {
    return true;
  }
}

export function inputMaxDate(value = 0) {
  const today = new Date();
  today.setDate(today.getDate() + value);
  return today.toISOString().split("T")[0];
}

export function micListener(analyzer) {}

export function inputIsEmailFormat(input) {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Test the input against the regular expression
  return emailRegex.test(input);
}

export function stringToSlug(input, separator = "-") {
  return input
    .toLowerCase()
    .replace(/ /g, separator)
    .replace(/[^\w-]+/g, "");
}

export function documentTitle(title) {
  document.title = "Highr ID - " + title;
}

export function rupiahFormat(input) {
  if (input === "") return "";
  return "Rp " + input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function truncateText(text, limit) {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + "...";
  } else {
    return text;
  }
}

export function normalizeToPercentage(totalResult, totalMinValue, totalMaxValue) {
  return ((totalResult - totalMinValue) / (totalMaxValue - totalMinValue) * 100).toFixed(0);
}
