import { Component } from "react";

class StatsTable2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      header: [],
    };
  }

  render() {
    const { filters, content, headers, checkbox = true } = this.props;
    return (
      <>
        {filters ? (
          <>
            <div className="flex">{filters.map((filter) => filter)}</div>
          </>
        ) : (
          ""
        )}

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr>
                {checkbox ? (
                  <>
                    <th className="py-3 text-sm text-gray-500 text-left">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                        type="checkbox"
                      />
                    </th>
                  </>
                ) : (
                  <></>
                )}
                {headers.length == 0 ? (
                  <></>
                ) : (
                  <>
                    {headers.map((header, index) => {
                      return (
                        <th
                          className={`${
                            (index == 0 ? "px-6" : "") +
                            " py-3 text-sm text-gray-500 text-left"
                          }`}
                        >
                          {header}
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
            </thead>
            <tbody className="bg-white">{content}</tbody>
          </table>
        </div>
      </>
    );
  }
}

export default StatsTable2;
