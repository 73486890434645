import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardProfile from "./Pages/Client/DashboardProfile";
import JobsPage from "./Pages/Client/JobsPage";
import PositionDetailPage from "./Pages/Client/JobsPage/DetailPage";
import PositionPreviewImportPage from "./Pages/Client/JobsPage/PreviewImportPage";
import PositionCreatePage from "./Pages/Client/JobsPage/CreatePage";
import MyCandidatesPage from "./Pages/Client/MyCandidatesPage";
import MyDetailCandidatesPage from "./Pages/Client/MyCandidatesPage/DetailPage";
import MainLayout from "./Layouts/MainLayout";
import ReportsPage from "./Pages/Client/ReportsPage";
import ReportCandidatesPage from "./Pages/Client/ReportsPage/CandidatePage";
import UserManagementPage from "./Pages/Client/UserManagementPage";
import CreateUserPage from "./Pages/Client/UserManagementPage/CreatePage";
import CompanyProfilesPage from "./Pages/Client/CompanyProfilesPage";
import CompanyProfilesEditPage from "./Pages/Client/CompanyProfilesPage/EditPage";
import SettingsPage from "./Pages/Client/SettingsPage";
import InterviewPage from "./Pages/Client/InterviewPage";
import InterviewCreatePage from "./Pages/Client/InterviewPage/CreatePage";
import InterviewDetailPage from "./Pages/Client/InterviewPage/DetailPage";
import HelpsPage from "./Pages/Client/HelpsPage";
import PackagesPage from "./Pages/Client/PackagesPage";
import HistoryPage from "./Pages/Client/PackagesPage/HistoryPage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchPermissions } from "./features/user/userSlice";
import PageSpinner from "./Component/PageSpinner";
import UnauthorizedPage from "./Pages/Unauthorized";
import CandidateComparisonPage from "./Pages/Client/JobsPage/ComparisonPage";
import DemoLayout from "./Layouts/DemoLayout";
import CreateTemplatePage from "./Pages/Client/Demo/PerformancePage/Template/CreateTemplatePage";
import PerformanceDashboardPage from "./Pages/Client/Demo/PerformancePage/PerformanceDashboardPage";
import TemplateListPage from "./Pages/Client/Demo/PerformancePage/Template/TemplateListPage";
import AppraisalListPage from "./Pages/Client/Demo/PerformancePage/Appraisal/AppraisalListPage";
import AppraisalCreatePage from "./Pages/Client/Demo/PerformancePage/Appraisal/AppraisalCreatePage/AppraisalCreatePage";
import AppraisalDetailPage from "./Pages/Client/Demo/PerformancePage/Appraisal/AppraisalDetailPage";
import AppraisalAssessmentPage from "./Pages/Client/Demo/PerformancePage/Appraisal/AppraisalAssessmentPage";
import EditTemplatePage from "./Pages/Client/Demo/PerformancePage/Template/EditTemplatePage";

const Protected = ({ element, authorized = false }) => {
  if (!authorized) {
    return <UnauthorizedPage />;
  }
  return element;
};

const AppClient = () => {
  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || { base_role: null };
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (permission) {
      setLoading(false);
    }
  }, [permission]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <PageSpinner />
        <p className="text-gray-500 text-sm font-semibold">Loading App...</p>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="dashboard" element={<DashboardProfile />} />
          {/* JOBS */}
          <Route
            path="jobs"
            element={
              <Protected
                element={<JobsPage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          <Route
            path="jobs/detail/:id"
            element={
              <Protected
                element={<PositionDetailPage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          <Route
            path="jobs/detail/:id/import-preview/:importJobId"
            element={<PositionPreviewImportPage />}
          />
          <Route
            path="jobs/create"
            element={
              <Protected
                element={<PositionCreatePage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          {/* INTERVIEWS */}
          <Route
            path="interviews"
            element={
              <Protected
                element={<InterviewPage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          <Route
            path="interviews/create"
            element={
              <Protected
                element={<InterviewCreatePage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          <Route
            path="interviews/detail/:id"
            element={
              <Protected
                element={<InterviewDetailPage />}
                authorized={permission?.job_role?.includes("read")}
              />
            }
          />
          {/* MY CANDIDATES */}
          <Route
            path="my-candidates"
            element={
              <Protected
                element={<MyCandidatesPage />}
                authorized={permission?.my_candidate?.includes("read")}
              />
            }
          />
          <Route
            path="my-candidates/:id/:candidate_job_id"
            element={
              <Protected
                element={<MyDetailCandidatesPage />}
                authorized={permission?.my_candidate?.includes("read")}
              />
            }
          />
          {/* CANDIDATE COMPARISON */}
          <Route
            path="my-candidates/:position_slug/comparison"
            element={
              <Protected
                element={<CandidateComparisonPage />}
                authorized={permission?.my_candidate?.includes("read")}
              />
            }
          />
          {/* REPORTS */}
          <Route
            path="reports"
            element={
              <Protected
                element={<ReportsPage />}
                authorized={permission?.candidate_report?.includes("read")}
              />
            }
          />
          <Route
            path="reports/candidate-list/:job_id"
            element={
              <Protected
                element={<ReportCandidatesPage />}
                authorized={permission?.candidate_report?.includes("read")}
              />
            }
          />
          {/* USERS */}
          <Route
            path="users-management"
            element={
              <Protected
                element={<UserManagementPage />}
                authorized={permission?.user_management?.includes("read")}
              />
            }
          />
          {user.base_role === "owner" && (
            <Route
              path="users-management/create"
              element={<CreateUserPage />}
            />
          )}
          {/* COMPANY PROFILE */}

          <Route
            path="company-profile"
            element={
              <Protected
                element={<CompanyProfilesPage />}
                authorized={permission?.company_profile?.includes("read")}
              />
            }
          />

          <Route
            path="company-profile/edit"
            element={
              <Protected
                element={<CompanyProfilesEditPage />}
                authorized={permission?.company_profile?.includes("update")}
              />
            }
          />
          {/* SUBSCRIPTIONS */}

          <Route
            path="packages"
            element={
              <Protected
                element={<PackagesPage />}
                authorized={permission?.package?.includes("read")}
              />
            }
          />

          <Route
            path="packages/history"
            element={
              <Protected
                element={<HistoryPage />}
                authorized={permission?.package?.includes("read")}
              />
            }
          />

          {/* SETTINGS */}
          <Route
            path="settings"
            element={
              <Protected
                element={<SettingsPage />}
                authorized={permission?.setting?.includes("read")}
              />
            }
          />
          {/* HELPS */}
          <Route path="helps" element={<HelpsPage />} />
        </Route>
        <Route element={<DemoLayout />}>
          <Route
            path="demo/performance/dashboard"
            element={<PerformanceDashboardPage />}
          />
          <Route
            path="demo/performance/templates"
            element={<TemplateListPage />}
          />
          <Route
            path="demo/performance/templates/:id/edit"
            element={<EditTemplatePage />}
          />
          <Route
            path="demo/performance/template-create"
            element={<CreateTemplatePage />}
          />
          <Route
            path="demo/performance/appraisals"
            element={<AppraisalListPage />}
          />
          <Route
            path="demo/performance/appraisals/:id"
            element={<AppraisalDetailPage />}
          />
          <Route
            path="demo/performance/appraisals/:id/employee/:employee_id/assessment"
            element={<AppraisalAssessmentPage />}
          />
          <Route
            path="demo/performance/appraisal-create"
            element={<AppraisalCreatePage />}
          />
        </Route>
      </Routes>
    );
  }
};

export default AppClient;
