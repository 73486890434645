import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import PaginationTable from "../../../../../Component/Tables/PaginationTable";
import TableSpinner from "../../../../../Component/TableSpinner";
import EmptyContent from "../../../../../Component/Tables/EmptyContent";
import { Dropdown } from "flowbite-react";
import DropdownDots from "../../../../../Component/Tables/DropdownDots";
import { getAppraisals } from "../../../../../api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SearchTable from "../../../../../Component/Tables/SearchTable";
import { setAppraisals } from "../../../../../features/appraisal/appraisalSlice";
const AppraisalDataTable = () => {
  let navigate = useNavigate();
  //   const permission = useSelector((state) => state.user.permissions);
  //   const [user] = useState(() => {
  //     return JSON.parse(localStorage.getItem("user")) || null;
  //   });
  const {
    data: appraisals,
    itemsPerPage,
    itemsFrom,
    itemsTo,
    totalItems,
    currentPage,
  } = useSelector((state) => state.appraisal.appraisalTable);
  
  const [tableLoading, setTableLoading] = useState(1);
  const [query, setQuery] = useState(null);
  const dispatch = useDispatch();

  const fetchAppraisals = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getAppraisals(queryString);
    if (result.success) {
      dispatch(setAppraisals(result));
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDetail = (id) => {
    navigate(`/client/demo/performance/appraisals/${id}`);
  };

  useEffect(() => {
    fetchAppraisals();
  }, [query]);

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    // setQuery({ ...query, position: "", department: "" });
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {appraisals.length > 0 ? (
            appraisals.map((value, index) => {
              return (
                <tr
                  key={value.id}
                  className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer"
                  onClick={() => handleDetail(value.id)}
                >
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {itemsPerPage * (currentPage - 1) + index + 1}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.name}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.company_job_position ? value.company_job_position.name : "-"}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.start_date}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.end_date}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.assessor_client? value.assessor_client.name : "-"}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{value.status_label}</div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value.created_at).format("YYYY-MM-DD")}
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StickyContent = () => {
    if (!tableLoading) {
      return (
        <>
          {appraisals.length > 0 ? (
            <>
              {appraisals.map((value) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[16.5px]">
                      <DropdownDots isPublish={value.is_publish}>
                        <Dropdown.Item onClick={() => handleDetail(value.id)}>
                          <InformationCircleIcon width={16} className="mr-2" />
                          Detail
                        </Dropdown.Item>
                      </DropdownDots>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  //   const StatusLabel = ({ status = 0, label = "" }) => {
  //     if (status == 0) {
  //       return (
  //         <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
  //           {label}
  //         </p>
  //       );
  //     } else if (status == 1) {
  //       return (
  //         <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
  //           {label}
  //         </p>
  //       );
  //     }
  //   };

  return (
    <>
      <ToastContainer />
      <div className="border-b border-gray-200 rounded-lg shadow">
        <PaginationTable
          checkbox={false}
          //   onClearFilter={(e) => handleClearFilter(e)}
          search={
            <SearchTable
              onSearch={(keyword) => handleSearch(keyword)}
              placeholder={"Search Appraisal Name"}
            />
          }
          isSticky={true}
          stickyHeader={["ACTION"]}
          stickyContent={<StickyContent />}
          //   filters={[]}
          currentPage={handlePaginate}
          itemsTo={itemsTo}
          itemsFrom={itemsFrom}
          itemsPerPage={5}
          totalItems={totalItems}
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          headers={[
            "NO",
            "NAME",
            "JOB ROLE",
            "START DATE",
            "END DATE",
            "ASSESSOR",
            "STATUS",
            "CREATED",
          ]}
          content={<TableContent />}
        />
      </div>
    </>
  );
};

export default AppraisalDataTable;
