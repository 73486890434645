import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import Slider from "react-slick";
import EmptyState from "../../Components/EmptyState";
const Personality = ({ professionalBehaviour, motivation, emotional, personalityTraits }) => {
    const [totalData, setTotalData] = useState([]);
    const [communicate, setCommunicate] = useState(null);
    const [adaption, setAdaption] = useState(null);
    const [manage, setManage] = useState(null);
    const [dare, setDare] = useState(null);
    const [excel, setExcel] = useState(null);
    const [motivationRadar, setMotivationRadar] = useState(null);
    const [emotionalRadar, setEmotionalRadar] = useState(null);
    const [personalityTraitsRadar, setPersonalityTraitsRadar] = useState(null);
    const settings = {
        centerMode: false,
        arrows: true,
        infinite: false,
        speed: 500,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                }
            }
        ]
    };
    useEffect(() => {
        if (professionalBehaviour) {
            const communication = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Communication') : null;
            const adaption = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Adaption') : null;
            const manage = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Management') : null;
            const dare = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Daring') : null;
            const excel = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Excel') : null;
            const motivation = professionalBehaviour?.result?.length > 0 ? professionalBehaviour.result.find(item => item.category === 'Motivations Radar') : null;

            if (communication) {
                let values = [];
                let labels = [];
                communication.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setCommunicate({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#B638FF7A',
                            borderColor: '#B638FF',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
            if (adaption) {
                let values = [];
                let labels = [];
                adaption.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setAdaption({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#E3A0087A',
                            borderColor: '#E3A008',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
            if (manage) {
                let values = [];
                let labels = [];
                manage.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setManage({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#F7931F7A',
                            borderColor: '#F7931F',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
            if (dare) {
                let values = [];
                let labels = [];
                dare.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setDare({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#3F83F87A',
                            borderColor: '#3F83F8',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
            if (excel) {
                let values = [];
                let labels = [];
                excel.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setExcel({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#0E9F6E7A',
                            borderColor: '#0E9F6E',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
            if (motivation) {
                let values = [];
                let labels = [];
                motivation.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setMotivationRadar({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#9061F97A',
                            borderColor: '#9061F9',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
        }

    }, [professionalBehaviour])

    useEffect(() => {
        if (emotional) {
            const emotionalData = emotional?.result?.length > 0 ? emotional.result[0] : null;
            if (emotionalData) {
                let values = [];
                let labels = [];
                emotionalData.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setEmotionalRadar({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#E746947A',
                            borderColor: '#E74694',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
        }

    }, [emotional])
    
    useEffect(() => {
        if (personalityTraits) {
            const emotionalData = personalityTraits?.result?.length > 0 ? personalityTraits.result[0] : null;
            if (emotionalData) {
                let values = [];
                let labels = [];
                emotionalData.data.map((item) => {
                    values.push(item.result)
                    labels.push(item.name)
                })
                setPersonalityTraitsRadar({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: '#E746947A',
                            borderColor: '#E74694',
                            borderWidth: 2,
                            hidden: '',
                        },
                    ],
                })
                setTotalData(totalData + 1);
            }
        }
    }, [personalityTraits])
    return (
        <div className='card flex flex-col lg:flex-row justify-between w-full mb-5 anti-cheat'>
            <div className='content w-full'>
                <div className='flex flex-col h-[100%]'>
                    <p className='text-lg text-[#374151] font-semibold mb-4'>Personality</p>
                    <div
                        className="grid grid-cols-3 gap-3"
                    >
                        {/* <Slider {...settings}> */}
                        {
                            communicate && <div>
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Communicate</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {communicate && <PersonalityRadarChart data={communicate} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            adaption && <div>
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Adaption</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {adaption && <PersonalityRadarChart data={adaption} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            manage && <div>
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Manage</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {manage && <PersonalityRadarChart data={manage} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            dare && <div >
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Dare</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {dare && <PersonalityRadarChart data={dare} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            excel && <div >
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Excel</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {excel && <PersonalityRadarChart data={excel} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            motivationRadar && <div>
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Motivation</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {motivationRadar && <PersonalityRadarChart data={motivationRadar} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            emotionalRadar && <div >
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Emotional Quotient</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {emotionalRadar && <PersonalityRadarChart data={emotionalRadar} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            personalityTraitsRadar && <div >
                                <div className="border border-[2px] border-purple-200 rounded-lg">
                                    <div className="title p-4">
                                        <span className="flex gap-2 items-center">
                                            <p className="font-semibold text-lg">Personality Traits</p>
                                            {/* <p className='bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 px-2 rounded text'>
                                                Medium
                                            </p> */}
                                        </span>

                                    </div>
                                    <hr />
                                    <div className="title">
                                        <div className='flex w-full items-center justify-center'>
                                            {personalityTraitsRadar && <PersonalityRadarChart data={personalityTraitsRadar} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* </Slider> */}
                        {totalData <= 0 && <EmptyState />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Personality;