import DropdownDots from "../../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import { Bars3CenterLeftIcon, PencilIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import "moment/locale/id";
import { getAppraisalEmployee } from "../../../../../api";
import PaginationTable from "../../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../../Component/Tables/SearchTable";
import EmptyContent from "../../../../../Component/Tables/EmptyContent";
import TableSpinner from "../../../../../Component/TableSpinner";
import { useNavigate } from "react-router-dom";

const AppraisalDataEmployee = ({ id }) => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    data: [],
    itemsPerPage: null,
    itemsFrom: null,
    itemsTo: null,
    totalItems: null,
    currentPage: null,
  });
  const [tableLoading, setTableLoading] = useState(1);

  const handleDelete = (id) => {
    onDelete(id);
  };

  const fetchAppraisalEmployee = async (appraisalId) => {
    setTableLoading(true);
    const result = await getAppraisalEmployee(appraisalId);
    setTableLoading(false);
    if (result.success) {
      setEmployee({
        data: result.data.data,
        itemsPerPage: result.data.per_page,
        itemsFrom: result.data.from,
        itemsTo: result.data.to,
        totalItems: result.data.total,
        currentPage: result.data.current_page,
      });
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDetail = (employee_id) => {
    navigate(
      `/client/demo/performance/appraisals/${id}/employee/${employee_id}/assessment`
    );
  };
  const handleEdit = (employee_id) => {
    navigate(
      `/client/demo/performance/appraisals/${id}/employee/${employee_id}/assessment?mode=edit`
    );
  };
  const handleRead = (employee_id) => {
    navigate(
      `/client/demo/performance/appraisals/${id}/employee/${employee_id}/assessment?mode=readonly`
    );
  };

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {employee.data.length > 0 ? (
            employee.data.map((value, index) => {
              return (
                <tr
                  key={value.id}
                  className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer"
                  // onClick={() => handleDetail(value.id)}
                >
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {employee.itemsPerPage * (employee.currentPage - 1) +
                        index +
                        1}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.employee.fullname}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.employee.email}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.employee.phonenumber}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.employee ? value.employee.date_of_birth : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.employee ? value.employee.gender : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.status_label}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const StickyContent = () => {
    if (!tableLoading) {
      return (
        <>
          {employee.data.length > 0 ? (
            <>
              {employee.data.map((value) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px]">
                    <td className="px-6 py-[16.5px]">
                      <DropdownDots>
                        {value.status == 1 && (
                          <Dropdown.Item onClick={() => handleDetail(value.id)}>
                            <PencilSquareIcon width={16} className="mr-2" />
                            Fill Assessment
                          </Dropdown.Item>
                        )}
                        {value.status == 2 && (
                          <>
                            <Dropdown.Item
                              onClick={() => handleEdit(value.id)}
                            >
                              <PencilIcon width={16} className="mr-2" />
                              Revision Assessment
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleRead(value.id)}
                            >
                              <Bars3CenterLeftIcon width={16} className="mr-2" />
                              See Result
                            </Dropdown.Item>
                          </>
                        )}
                      </DropdownDots>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (id) {
      fetchAppraisalEmployee(id);
    }
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="border-b border-gray-200 shadow mb-10">
          <PaginationTable
            checkbox={false}
            //   onClearFilter={(e) => handleClearFilter(e)}
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search Employee Name"}
              />
            }
            isSticky={true}
            stickyHeader={["ACTION"]}
            stickyContent={<StickyContent />}
            //   filters={[]}
            currentPage={handlePaginate}
            itemsTo={employee.itemsTo}
            itemsFrom={employee.itemsFrom}
            itemsPerPage={5}
            totalItems={employee.totalItems}
            totalPages={Math.ceil(employee.totalItems / employee.itemsPerPage)}
            headers={[
              "NO",
              "NAME",
              "EMAIL",
              "PHONENUMBER",
              "AGE",
              "GENDER",
              "STATUS",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </>
  );
};

export default AppraisalDataEmployee;
