// jobSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  appraisalTable: {
    loaded: false,
    data: [
      {
        id: null,
        name: null,
        description: "-",
        start_date: 0,
        end_date: "-",
        status: "-",
        created_at: "-",
        updated_at: "-",
      },
    ],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 1,
    itemsFrom: 1,
    itemsTo: 1,
    filterDepartments: [],
    filterPositions: [],
  },
};

const appraisalSlice = createSlice({
  name: "appraisalTemplate",
  initialState,
  reducers: {
    setAppraisals: (state, action) => {
      state.appraisalTable.data = action.payload.data.data;
      state.appraisalTable.totalItems = action.payload.data.total;
      state.appraisalTable.itemsPerPage = action.payload.data.per_page;
      state.appraisalTable.currentPage = action.payload.data.current_page;
      state.appraisalTable.itemsFrom = action.payload.data.from;
      state.appraisalTable.itemsTo = action.payload.data.to;
      state.appraisalTable.loaded = true;
    },
  },
});

export const { setAppraisals } = appraisalSlice.actions;
export default appraisalSlice.reducer;
