import React, { useState } from "react";
import { useEffect } from "react";
import Tooltip from "../../../../../Component/Tooltip";
import { normalizeToPercentage } from "../../../../../helpers";
import StatsTable2 from "../../../../../Component/Tables/StatsTable2";
const ComparisonPersonalityGantChart = ({ data = [], selectedCandidate }) => {
  const colorRanges = [
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-blue-purple-gradient",
    },
  ];

  const [remappedResult, setRemappedResult] = useState(null);
  const remappedData = () => {
    const selectedData = data.find(
      (x) => x.candidate.fullname === selectedCandidate
    );
    
    const remapped = {
      label: selectedData.candidate.fullname,
      data: selectedData.result.map((res, key2) => {
        return {
          name: res.test_name,
          data:
            res.test === "professional-behaviour"
              ? res.result.map((r) => {
                  const totalMaxValue = r.data
                    .map((d) => parseInt(d.max_value))
                    .reduce((a, b) => a + b, 0);
                  const totalMinValue = r.data
                    .map((d) => parseInt(d.min_value))
                    .reduce((a, b) => a + b, 0);
                  const totalResult = r.data
                    .map((d) => d.result)
                    .reduce((a, b) => a + b, 0);
                  return {
                    name: r.category,
                    totalMaxValue,
                    totalMinValue,
                    result: parseInt(
                      normalizeToPercentage(
                        totalResult,
                        totalMinValue,
                        totalMaxValue
                      )
                    )
                  };
                })
              : res.result[0].data.map((r) => {
                  return {
                    name: r.name,
                    totalMaxValue: parseInt(r.max_value),
                    totalMinValue: parseInt(r.min_value),
                    result: parseInt(
                      normalizeToPercentage(
                        parseInt(r.result),
                        parseInt(r.min_value),
                        parseInt(r.max_value)
                      )
                    ),
                  };
                }),
        };
      }),
    };
    setRemappedResult(remapped);
  };

  useEffect(() => {
    if(selectedCandidate){
      remappedData();
    }
    
  }, [data, selectedCandidate]);
  const TooltipContent = ({ overall = 0, average = 0 }) => {
    return (
      <>
        <div className="flex items-center mr-4">
          {overall >= 0 && overall <= 33 && (
            <>
              <span className="bg-red-500 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          {overall > 33 && overall <= 66 && (
            <>
              <span className="bg-yellow-300 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          {overall > 66 && (
            <>
              <span className="bg-green-500 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          <p className="font-semibold">
            Overall: <b>{overall}%</b>
          </p>
        </div>
        {/* <div className="flex items-center mr-4">
          <span className="bg-orange-secondary-600 h-[8px] w-[8px] rounded-full mr-2"></span>
          <p className="font-semibold">
            Benchmark: <b>{average}%</b>
          </p>
        </div> */}
      </>
    );
  };
  const StatusLabel = ({ value, max_value, custom_label = null }) => {
    const result_percentage = (value / max_value) * 100;
    if (result_percentage >= 0 && result_percentage <= 33) {
      return (
        <p className="text-label red text-sm mb-2">
          {custom_label ? custom_label : "Low"}
        </p>
      );
    } else if (result_percentage > 33 && result_percentage <= 66) {
      return (
        <p className="text-label yellow text-sm mb-2">
          {custom_label ? custom_label : "Medium"}
        </p>
      );
    } else if (result_percentage > 66) {
      return (
        <p className="text-label green text-sm mb-2">
          {custom_label ? custom_label : "High"}
        </p>
      );
    }
  };
  const TableContent = ({ content = [], index = 0 }) => {
    let total_row = 0;
    const table_content = content.map((item, index2) => {
      if (item.result.length <= 0) {
        return <></>;
      } else {
        return (
          <>
            <tr className="whitespace-nowrap  bg-gray-50 rounded-lg">
              {/* <td className="px-6 py-3 text-sm text-gray-900 mdb:max-w-[50px] font-semibold w-[5%]">
                <Avatar
                  className="hidden mdb:block"
                  alt="User settings"
                  img={item.avatar}
                  rounded={true}
                />
              </td> */}
              <td className="px-6 py-3 text-sm text-gray-900 mdb:max-w-[100px] font-semibold mdb:w-[30%] truncate">
                {item.name}
              </td>
              {/* <td className="py-4 pr-6 mdb:pr-0 text-sm text-gray-500 mdb:max-w-[90px] mdb:w-[15%]">
                <StatusLabel
                  value={total_result - total_min_value}
                  max_value={total_max_value - total_min_value}
                  custom_label={
                    item.custom_result_label ? item.total_result_label : null
                  }
                />
              </td> */}
              {/* <td className="py-4 pr-6 text-sm text-gray-500 flex flex-col">
                <p className="text-lg mb-1 text-gray-700">
                  <b>{result_percentage}%</b>{" "}
                </p>
              </td> */}

              <td className="py-4 pl-3 pr-3 text-sm text-gray-500 mdb:w-[60%]">
                <div className="flex flex-col items-center justify-center">
                  <Tooltip
                    type="custom"
                    top="-58px"
                    element={
                      <TooltipContent
                        overall={item.result}
                      />
                    }
                  >
                    <div className="flex flex-col">
                      {/* <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                      <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`bg-gray-300 h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${benchmark_percentage}%`,
                          }}
                        ></div>
                      </div> */}
                      <div className="w-full h-[12px] bg-gray-100 relative">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${item.result}%`,
                          }}
                        ></div>
                        {/* <div
                          className={`h-[21px] text-center text-xs text-white border-r-[3px] border-[#605F5E] absolute top-[-4.5px]`}
                          style={{
                            width: `${benchmark_percentage}%`,
                          }}
                        ></div> */}
                      </div>
                    </div>
                  </Tooltip>
                  {/* <Tooltip
                    type="custom"
                    top="-58px"
                    element={<TooltipContent overall={benchmark_percentage} />}
                  ></Tooltip> */}
                </div>
                {/* {result_percentage >= 0 && result_percentage <= 33 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )}
                {result_percentage > 33 && result_percentage <= 66 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px]  bg-gray-100">
                        <div
                          className="h-full text-center text-xs text-white bg-blue-purple-gradient rounded-r-sm"
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )}
                {result_percentage > 66 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px]  bg-gray-100">
                        <div
                          className="h-full text-center text-xs text-white bg-green-500 rounded-r-sm"
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )} */}
              </td>
              <td className="text-sm text-gray-900 mdb:max-w-[100px] font-medium pb-1">
                {item.result}%
              </td>
            </tr>
            <tr className="h-[8px]"></tr>
          </>
        );
      }
    });
    return table_content;
  };
  return (
    <div className="grid grid-col-1 xl:grid-cols-2 4xl:grid-cols-3 gap-2">
      {remappedResult && remappedResult.data.map((item, index) => (
        <div className="card-mini mdb:card flex flex-col lg:flex-row justify-between w-full anti-cheat mdb:mt-0">
          <div className="content w-full">
            <div className="flex flex-col h-[100%]">
              <p className="mdb:text-lg text-[#374151] font-semibold mb-1">
                {item.name}
              </p>

              <StatsTable2
                checkbox={false}
                headers={["Result", "", ""]}
                content={<TableContent content={item.data} index={index}/>}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComparisonPersonalityGantChart;
