import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AcademicCapIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { useSelector } from "react-redux";

const PerformanceDashboardPage = () => {
  const permission = useSelector((state) => state.user.permissions);
  const { data: positions } = useSelector((state) => state.jobs.jobsTable);
  const { summary } = useSelector((state) => state.dashboards);
  let navigate = useNavigate();
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <h2 className="text-lg md:text-xl font-semibold leading-[150%] mb-6">
        Performance Dashboard
      </h2>
    </div>
  );
};

export default PerformanceDashboardPage;
