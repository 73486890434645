// jobSlice.js
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  templateTable: {
    loaded: false,
    data: [
      {
        id: null,
        name: null,
        description: "-",
        status: 0,
        status_label: "-",
        total_aspects: "-",
        total_indicators: "-",
        created_at: "-",
        updated_at: "-",
      },
    ],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 1,
    itemsFrom: 1,
    itemsTo: 1,
    filterDepartments: [],
    filterPositions: [],
  },
};

const appraisalTemplateSlice = createSlice({
  name: "appraisalTemplate",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.templateTable.data = action.payload.data.data;
      state.templateTable.totalItems = action.payload.data.total;
      state.templateTable.itemsPerPage = action.payload.data.per_page;
      state.templateTable.currentPage = action.payload.data.current_page;
      state.templateTable.itemsFrom = action.payload.data.from;
      state.templateTable.itemsTo = action.payload.data.to;
      state.templateTable.loaded = true;
    },
  },
});

export const { setTemplates } = appraisalTemplateSlice.actions;
export default appraisalTemplateSlice.reducer;
