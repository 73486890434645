import axios from 'axios';
import moment from 'moment';

export const getCandidateCompareSoftSkill = async (candidates) => {
    const response = await axios
        .get(`/api/client/candidate-comparison/soft-skill?candidates=${candidates}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}
export const getCandidateCompareCognitive = async (candidates) => {
    const response = await axios
        .get(`/api/client/candidate-comparison/cognitive?candidates=${candidates}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}
export const getPersonalityCandidates = async (candidates) => {
    const response = await axios
        .get(`/api/client/candidate-comparison/personality?candidates=${candidates}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    return response.data
}