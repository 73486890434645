import { React, useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BriefcaseIcon,
  CheckIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import CreatePosition from "./Sections/CreatePosition";
import SelectTest from "./Sections/SelectTest";
import PreviewTest from "./Sections/PreviewTest";
import BtnSpinner from "../../../Component/BtnSpinner";
import BreadCrumb from "../../../Component/BreadCrumb";
import PopUp from "../../../Component/PopUp";
import CreateJobTokenPopup from "./Popup/CreateJobTokenPopup";
import { getMySubscriptions, validateSubscriptionSerial } from "../../../api";
import PageSpinner from "../../../Component/PageSpinner";
import config from "../../../config";
import QuotaExceedPopup from "./Popup/QuotaExceedPopup";
import { documentTitle } from "../../../helpers";
import { useSelector } from "react-redux";
const CreatePage = () => {

  const permission = useSelector((state) => state.user.permissions);
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  let navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [formPosition, setFormPosition] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [positions, setPositions] = useState([]);
  const [tests, setTests] = useState([]);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showCreateJobTokenPopup, setShowCreateJobTokenPopup] = useState(false);
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const [validating, setValidating] = useState(false);
  const [checking, setChecking] = useState(true);
  const [createable, setCreateable] = useState(false);
  const [packageApplied, setPackageApplied] = useState(null);
  const [quotaTest, setQuotaTest] = useState(null);
  const [showQuotaExceed, setShowQuotaExceed] = useState(false);
  const [currentSerial, setCurrentSerial] = useState(null);
  const [form, setForm] = useState({
    job_department_id: "",
    job_position_id: "",
    job_role: "",
    days_to_expiry: "",
    description: "",
    is_publish: 1,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("code")) {
      setCurrentSerial(params.get("code"));
    }
  }, []);

  const getDepartments = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-department-list"
      );
      const result = response.data;
      if (result.success) {
        setDepartments(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getPositions = async () => {
    try {
      const response = await axios.get(
        "/api/client/assessment/job-position-list"
      );
      const result = response.data;
      if (result.success) {
        setPositions(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getTests = async () => {
    try {
      const response = await axios.get("/api/client/assessment/test/list");
      const result = response.data;
      if (result.success) {
        setTests(result.data);
        setFilterAssessment(result.additional.filter.assessments);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    documentTitle("Create Jobs");
    getDepartments();
    getPositions();
    getTests();
  }, []);

  useEffect(() => {
    if (formPosition == 1) {
      if (
        form.days_to_expiry != "" &&
        form.job_department_id != "" &&
        form.job_position_id != "" &&
        form.job_role != ""
      ) {
        setFormPassed(true);
      } else {
        setFormPassed(false);
      }
    }
  }, [form, formPosition]);

  const handleSelectedTest = (testId) => {
    if (quotaTest != null) {
      // LIMITED TEST
      if (quotaTest > 0) {
        const temps = tests.map((test) => {
          if (test.id == testId) {
            const tempSelected = selectedTest;
            tempSelected.push({
              assessment_id: test.assessment.id,
              test_id: test.id,
            });
            if (tempSelected.length > 0) {
              setFormPassed(true);
            }
            setSelectedTest(tempSelected);
            setQuotaTest(quotaTest - 1);
            return { ...test, selected: true };
          } else {
            return { ...test };
          }
        });
        setTests(temps);
      } else {
        setShowQuotaExceed(true);
      }
    } else {
      // UNLIMITED TEST
      const temps = tests.map((test) => {
        if (test.id == testId) {
          const tempSelected = selectedTest;
          tempSelected.push({
            assessment_id: test.assessment.id,
            test_id: test.id,
          });
          if (tempSelected.length > 0) {
            setFormPassed(true);
          }
          setSelectedTest(tempSelected);
          return { ...test, selected: true };
        } else {
          return { ...test };
        }
      });
      setTests(temps);
    }
  };
  const handleRemoveTest = (testId) => {
    const temps = tests.map((test) => {
      if (test.id == testId) {
        const tempSelected = selectedTest.filter(
          (item) => item.test_id !== testId
        );
        if (tempSelected.length > 0) {
          setFormPassed(true);
        } else {
          setFormPassed(false);
        }
        setSelectedTest(tempSelected);
        if (quotaTest != null) {
          setQuotaTest(quotaTest + 1);
        }
        return { ...test, selected: false };
      } else {
        return { ...test };
      }
    });
    setTests(temps);
  };

  const handlNextSection = () => {
    setFormPosition(2);
    if (formPosition == 2) {
      setFormPassed(true);
      setFormPosition(3);
      setSelectedDepartment(
        departments.find(
          (department) => department.id == form.job_department_id
        )
      );
      setSelectedPosition(
        positions.find((position) => position.id == form.job_position_id)
      );
    } else {
      setFormPassed(false);
    }
  };

  const handleDeleteTest = async (id) => {
    const temps = tests.map((test) => {
      if (test.id == id) {
        const tempSelected = selectedTest;
        tempSelected.push({
          assessment_id: test.assessment.id,
          test_id: test.id,
        });
        const tempSelectedTests = selectedTest.filter(
          (test) => test.test_id != id
        );
        setSelectedTest(tempSelectedTests);
        return { ...test, selected: false };
      } else {
        return { ...test };
      }
    });
    setTests(temps);
    // const temps = selectedTest.filter((test) => test.test_id != id);
    // setSelectedTest(temps);
  };

  const handleDepartmentChange = (value) => {
    setForm({ ...form, job_department_id: value, job_position_id: "" });
  };

  const handleCreateJob = () => {
    // TEMPORARY FOR TEST
    // ENV DEV
    const isDev = config.APP_ENV == "development";
    if (isDev) {
      if (mySubscriptions.length > 0) {
        setCreateable(false);
        setShowCreateJobTokenPopup(true);
      } else {
        setCreateable(true);
        // navigate('/client/jobs/create')
      }
    } else {
      setCreateable(false);
      setShowCreateJobTokenPopup(true);
    }
  };

  const handleApplySerial = async (serial) => {
    setValidating(true);
    const result = await validateSubscriptionSerial({ serial });
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setPackageApplied(result.data);
      setQuotaTest(result.data.quota_test);
      setShowCreateJobTokenPopup(false);
      setCreateable(true);
      const params = new URLSearchParams(window.location.search);
      if (params.has("code")) {
        setCurrentSerial(null);
        params.delete("code");
        history.replace({
          search: queryParams.toString(),
        });
      }
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setValidating(false);
  };

  const fetchMySubscriptions = async () => {
    setChecking(true);
    const result = await getMySubscriptions();
    if (result.success) {
      setMySubscriptions(result.data);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setChecking(false);
  };

  const handleSubmit = async (e) => {
    if (!permission?.job_role?.includes("create")) {
      alert(
        "You don't have permission to create job. Please contact administrator."
      );
      return;
    }
    setSubmitting(true);
    try {
      const sanitizeForm = form;
      if (packageApplied != null) {
        sanitizeForm.company_subscription_id = packageApplied.id;
      }

      const createPosition = await axios.post(
        "/api/client/assessment/job-position",
        JSON.stringify(sanitizeForm),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resultCreatePosition = createPosition.data;
      if (resultCreatePosition.success) {
        const formTest = {
          company_job_position_id: resultCreatePosition.data.id,
          tests: selectedTest,
        };

        const submitTest = await axios.post(
          "/api/client/assessment/test/select",
          JSON.stringify(formTest),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resultSubmitTest = submitTest.data;
        if (resultSubmitTest.success) {
          toast.success("Create position success", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
            onClose: () =>
              navigate(`/client/jobs/detail/${resultCreatePosition.data.id}`, {
                replace: false,
              }),
          });
        } else {
          toast.error(resultSubmitTest.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitting(false);
        }
      } else {
        toast.error(resultCreatePosition.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitting(false);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitting(false);
    }
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    fetchMySubscriptions();
  }, []);

  useEffect(() => {
    handleCreateJob();
  }, [mySubscriptions]);

  // useEffect(() => {
  //   if(quotaTest != null && quotaTest == 0){

  //   }
  // }, [quotaTest]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      {showCreateJobTokenPopup && (
        <PopUp>
          <CreateJobTokenPopup
            currentSerial={currentSerial}
            onClose={(e) => setShowCreateJobTokenPopup(false)}
            onSend={(serial) => handleApplySerial(serial)}
            validating={validating}
          />
        </PopUp>
      )}
      {showQuotaExceed && (
        <PopUp>
          <QuotaExceedPopup onClose={(e) => setShowQuotaExceed(false)} />
        </PopUp>
      )}
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BriefcaseIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Job</p>
          <BreadCrumb
            list={[
              { href: "/client/jobs", title: "Jobs" },
              { href: null, title: "Create" },
            ]}
          />
        </div>
      </div>
      {checking && <PageSpinner />}
      {!checking && (
        <>
          {!createable && (
            <div className="card mb-6">
              <div className="w-full py-6 flex flex-col items-center">
                <LockClosedIcon width={120} className="text-gray-500" />
                <p className="mt-4 mb-4 text-gray-600">Page Locked</p>
                <button
                  className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[42px] rounded-lg disabled:bg-violet-300 disabled:cursor-not-allowed"
                  onClick={(e) => setShowCreateJobTokenPopup(true)}
                >
                  Apply Subscription Code
                </button>
              </div>
            </div>
          )}
          {createable && (
            <>
              <div className="card mb-6">
                <div className="w-full py-6">
                  <div className="flex">
                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div className="w-12 h-12 mx-auto bg-violate-100 rounded-full text-lg text-white flex items-center px-2">
                          {formPosition > 1 ? (
                            <>
                              <CheckIcon className="text-main-violate-600" />
                            </>
                          ) : (
                            <>
                              <BriefcaseIcon className="text-main-violate-600" />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="text-xs text-center md:text-base">
                        Position
                      </div>
                    </div>

                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div
                          className="absolute flex align-center items-center align-middle content-center"
                          style={{
                            width: "calc(100% - 2.5rem - 1rem)",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                            <div
                              className="w-0 bg-main-violate-100 py-1 rounded"
                              style={
                                formPosition > 1
                                  ? { width: "100%" }
                                  : { width: "0%" }
                              }
                            ></div>
                          </div>
                        </div>
                        <div
                          className={
                            "w-12 h-12 mx-auto rounded-full text-lg text-white flex items-center px-2 bg-violate-100"
                          }
                        >
                          {formPosition > 2 ? (
                            <>
                              <CheckIcon className="text-main-violate-600" />
                            </>
                          ) : (
                            <>
                              <ClipboardDocumentListIcon
                                className={
                                  formPosition > 1
                                    ? "text-main-violate-600"
                                    : "text-grey-500"
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="text-xs text-center md:text-base">
                        Select Test
                      </div>
                    </div>

                    <div className="w-1/3">
                      <div className="relative mb-2">
                        <div
                          className="absolute flex align-center items-center align-middle content-center"
                          style={{
                            width: "calc(100% - 2.5rem - 1rem)",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                            <div
                              className="w-0 bg-main-violate-100 py-1 rounded"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                        </div>

                        <div className="w-12 h-12 mx-auto bg-violate-100 rounded-full text-lg text-white flex items-center px-2">
                          <DocumentMagnifyingGlassIcon
                            className={
                              formPosition > 2
                                ? "text-main-violate-600"
                                : "text-grey-500"
                            }
                          />
                        </div>
                      </div>

                      <div className="text-xs text-center md:text-base">
                        Preview Test
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-6 overflow-x-hidden ">
                {formPosition == 1 ? (
                  <>
                    <CreatePosition
                      departments={departments}
                      positions={positions}
                      jobDepartmentId={form.job_department_id}
                      jobPositionId={form.job_position_id}
                      jobRole={form.job_role}
                      onDepartmentChange={(value) =>
                        handleDepartmentChange(value)
                      }
                      onPositionChange={(value) =>
                        setForm({ ...form, job_position_id: value })
                      }
                      onRoleChange={(value) =>
                        setForm({ ...form, job_role: value })
                      }
                      daysToExpiry={form.days_to_expiry}
                      onDayChange={(value) =>
                        setForm({ ...form, days_to_expiry: value })
                      }
                    />
                  </>
                ) : formPosition == 2 ? (
                  <>
                    <SelectTest
                      tests={tests}
                      onSelect={handleSelectedTest}
                      onRemove={handleRemoveTest}
                      filterAssessment={filterAssessment}
                      quotaTest={quotaTest}
                    />
                  </>
                ) : (
                  <PreviewTest
                    form={form}
                    department={selectedDepartment}
                    position={selectedPosition}
                    tests={tests.filter((test) => test.selected == true)}
                    onDelete={(e) => handleDeleteTest(e)}
                  />
                )}
                <div className="flex justify-end">
                  {formPosition > 1 ? (
                    <>
                      <button
                        className={`${
                          (submitting ? "hidden " : "") +
                          "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                        }`}
                        onClick={(e) => {
                          setFormPosition(formPosition - 1);
                        }}
                      >
                        Previous
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`${
                          (submitting ? "hidden " : "") +
                          "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                        }`}
                        type="submit"
                        onClick={(e) => {
                          navigate("/client/jobs");
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  )}

                  {submitting ? (
                    <button
                      className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type=""
                      disabled={true}
                    >
                      <BtnSpinner />
                    </button>
                  ) : (
                    <button
                      className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type=""
                      disabled={!formPassed}
                      onClick={(e) => {
                        if (formPosition < 3) {
                          handlNextSection();
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {formPosition < 3 ? "Next" : "Finish & Submit"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreatePage;
