import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FolderIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../../Component/BreadCrumb";
import AppraisalDataTable from "../Components/AppraisalDataTable";
import AppraisalDataDetail from "../Components/AppraisalDataDetail";
import AppraisalDataEmployee from "../Components/AppraisalDataEmployee";

const AppraisalDetailPage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <FolderIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Detail Appraisal</p>
          <BreadCrumb
            list={[
              {
                href: "/client/demo/performance/appraisals",
                title: "Appraisal",
              },
              { href: null, title: id },
            ]}
          />
        </div>
      </div>

      <div className="report-list">
        <div className="card flex flex-col w-full mb-6">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Appraisal Information
              </p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
            <div className="flex justify-end w-2/2">
              {/* {permission?.job_role?.includes("create") && ( */}
              {/* <button
                className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                onClick={(e) => {
                  navigate("/client/demo/performance/appraisal-create");
                }}
              >
                <PlusCircleIcon width={20} className="mr-2" /> Create Appraisal
              </button> */}
              {/* )} */}
            </div>
          </div>
          <AppraisalDataDetail id={id} />
        </div>
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Employee List
              </p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
            <div className="flex justify-end w-2/2">
              {/* {permission?.job_role?.includes("create") && ( */}
              {/* <button
                className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
                type=""
                onClick={(e) => {
                  navigate("/client/demo/performance/appraisal-create");
                }}
              >
                <PlusCircleIcon width={20} className="mr-2" /> Create Appraisal
              </button> */}
              {/* )} */}
            </div>
          </div>
          <AppraisalDataEmployee id={id} />
        </div>
      </div>
    </div>
  );
};

export default AppraisalDetailPage;
