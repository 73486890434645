import SimpleTable from "../../../../../../../Component/Tables/SimpleTable";
import DropdownDots from "../../../../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import PopUp from "../../../../../../../Component/PopUp";
import DetailTestPreview from "../../../../../../../Component/DetailTestPreview";
import { useState } from "react";
import "moment/locale/id";

const PreviewSession = ({
  form,
  role,
  assessor,
  template,
  tests,
  onDelete,
  selectedCandidates,
  onShowProfile
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [showSelectedDetail, setShowSelectedDetail] = useState({});
  const handleDetail = (id) => {
    setShowSelectedDetail(tests.find((test) => test.id === id));
    setShowDetail(true);
  };
  const handleDelete = (id) => {
    onDelete(id);
  };
  const TableContent = () => {
    return (
      <>
        {selectedCandidates.map((value, index) => {
          return (
            <tr className="whitespace-nowrap border-b-[1px]">
              <td className="px-6 py-4">
                <div className="text-sm text-gray-500">{value.name}</div>
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">{value.email}</td>
              <td className="px-6 py-4 text-sm text-gray-500">
                {value.phonenumber}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">{value.gender}</td>
              <td className="px-6 py-4">
                <DropdownDots isPublish={value.is_publish}>
                  <Dropdown.Item onClick={(e) => onShowProfile(value)}>
                    <InformationCircleIcon width={16} className="mr-2" />
                    Detail
                  </Dropdown.Item>
                  <Dropdown.Item onClick={(e) => handleDelete(value)}>
                    <TrashIcon width={16} className="mr-2" />
                    Delete
                  </Dropdown.Item>
                </DropdownDots>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="card mb-6">
        <div className="flex flex-col justify-around mb-7">
          <p className="text-xl text-grey-600 font-bold">Appraisal Detail</p>
        </div>
        <div className="grid-cols-1 md:grid-cols-2 grid gap-4 ">
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              Name / Goal
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="title"
              id="title"
              placeholder="Type your job role..."
              value={form.name}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              Assessor
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="title"
              id="title"
              placeholder="Type your job role..."
              value={assessor ? assessor.label : ""}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              Job Role
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="title"
              id="title"
              placeholder="Type your job role..."
              value={role ? role.label : ""}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              Start Date
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="date"
              name="start_date"
              id="start_date"
              placeholder="Choose date..."
              value={form.start_date}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              End Date
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="date"
              name="end_date"
              id="end_date"
              placeholder="Choose date..."
              value={form.end_date}
              disabled={true}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-800 text-sm font-bold leading-[140%]">
              Template
            </label>
            <input
              className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              type="text"
              name="template"
              id="template"
              placeholder="Choose date..."
              value={template ? template.label : ""}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="card mb-6">
        <div className="flex flex-col justify-around mb-7">
          <p className="text-xl text-grey-600 font-bold">Employees</p>
        </div>
        <div className="border-b border-gray-200 shadow mb-10">
          <SimpleTable
            checkbox={false}
            headers={["NAME", "EMAIL", "PHONE", "GENDER", "ACTION"]}
            content={<TableContent />}
          />
        </div>
      </div>
      {showDetail ? (
        <PopUp>
          <DetailTestPreview
            data={showSelectedDetail}
            onClose={(e) => setShowDetail(false)}
          />
        </PopUp>
      ) : (
        ""
      )}
    </>
  );
};

export default PreviewSession;
