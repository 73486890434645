import axios from "axios";
import moment from "moment";

export const createAppraisal = async (data) => {
  const response = await axios.post(`/api/client/appraisals`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getAppraisals = async (queryString = "") => {
  const response = await axios.get(`/api/client/appraisals?${queryString}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getAppraisalDetail = async (id) => {
  const response = await axios.get(`/api/client/appraisals/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getAppraisalEmployee = async (id) => {
  const response = await axios.get(`/api/client/appraisals/${id}/employees`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const getAppraisalEmployeeForm = async (id, employeeId) => {
  const response = await axios.get(
    `/api/client/appraisals/${id}/employee-form/${employeeId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
export const postAppraisalEmployeeForm = async (id, employeeId, data) => {
  const response = await axios.post(
    `/api/client/appraisals/${id}/employee-form/${employeeId}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const getAppraisalEmployeeInJobRole = async (
  companyJobPositionId,
  queryString = ""
) => {
  const response = await axios.get(
    `/api/client/appraisals/candidate-job-position/${companyJobPositionId}?limit=100`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};
