import { React, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Compressor from "compressorjs";
import axios from "axios";
import { PhotoIcon, NewspaperIcon } from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import { documentTitle } from "../../../../helpers";
import Select from "react-select";
import {
  createArticleContent,
  getArticleCategories,
  getArticleContent,
  updateArticleContent,
} from "../../../../api/admin";
import BtnSpinner from "../../../../Component/BtnSpinner";
import ReactQuill from "react-quill";

const BlogContentCreatePage = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [information, setInformation] = useState({});
  const [article, setArticle] = useState({
    title: "",
    slug: "",
    lang: "",
    caption: "",
    description: "",
    content: "",
    keywords: "",
    tags: "",
    author: "",
    is_publish: "",
    image: "",
  });
  const [query, setQuery] = useState({});
  const [categories, setCategories] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [uploading, setUploading] = useState(false);
  const imageFileRef = useRef(null);

  const languagesData = [
    { value: "en", label: "English" },
    { value: "id", label: "Indonesia" },
  ];

  const statusData = [
    { value: 0, label: "Draft" },
    { value: 1, label: "Publish" },
  ];
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const fetchPackageTransaction = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getArticleCategories(queryString);
    if (result.success) {
      setCategories(result.data.data);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const fetchArticleContent = async () => {
    const result = await getArticleContent(id);
    if (result.success) {
      let data = result.data;
      data.tags = data?.tags?.join(",");
      setArticle(result.data);
      setSelectedLanguage(languagesData.find((x) => x.value === data.lang));
      setSelectedStatus(statusData.find((x) => x.value === data.is_publish));
      setInformation({ url: data.image });
    }
  };

  useEffect(() => {
    documentTitle("Blog Management");
    fetchPackageTransaction();
    if (id) {
      fetchArticleContent();
    }
  }, []);

  useEffect(() => {
    if (categories && article.category_id && article.category_id.length > 0) {
      if (id) {
        const category = categories.filter(
          (x) => article.category_id.includes(x.id)
        ).map((x) => ({ value: x.id, label: x.name }));
        
        setSelectedCategory(category);
      }
    }
  }, [categories, id, article.category_id]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const leftCard = {
    boxShadow:
      "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: "12px",
    background: "#FFF",
    padding: "24px",
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let result = null;
    if (id) {
      result = await updateArticleContent(id, article);
    } else {
      result = await createArticleContent(article);
    }
    if (result.success) {
      // resetArticle();
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        onClose: () =>
          navigate("/administrator/blog/contents", { replace: false }),
      });
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
    setSubmitting(false);
  };

  // const resetArticle = () => {
  //   setArticle({
  //     title: "",
  //     slug: "",
  //     lang: "",
  //     caption: "",
  //     description: "",
  //     content: "",
  //     keywords: "",
  //     tags: "",
  //     author: "",
  //     is_publish: "",
  //     image: "",
  //   });

  //   setSelectedCategory(null);
  //   setSelectedLanguage(null);
  //   setSelectedStatus(null);
  // };
  const upload = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (!file) {
      return;
    }
    if (file.size > maxSize) {
      alert(
        "Image size exceeds the maximum limit (2MB). Please select a smaller image."
      );
      return;
    }

    setUploading(true);
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 500,
      minHeight: 500,
      mimeType: "image/webp",
      success(result) {
        const formData = new FormData();
        formData.append("image_file", result);
        axios
          .post("/api/admin/article-image/upload", formData)
          .then((response) => {
            const result = response.data;
            if (result.success) {
              setInformation(result.data);
              toast.success(result.message, {
                toastId: "login-success-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1250,
              });
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message, {
              toastId: "login-error-toast",
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .finally(() => {
            setUploading(false);
          });
      },
      error() {},
    });
  };

  useEffect(() => {
    setArticle({
      ...article,
      image: information.url,
    });
  }, [information]);

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <NewspaperIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Content Blog</p>
          <BreadCrumb
            list={[
              { href: "/administrator/blog/contents", title: "Blog" },
              { href: null, title: "Create" },
            ]}
          />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-6">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Content Form</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
        </div>
        <div className="w-full mdb:w-[100%] flex space-x-4 gap-4">
          <div className="w-[100%] flex flex-col space-y-4">
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Category
              </label>
              <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                <Select
                  isMulti={true}
                  className="w-full "
                  classNamePrefix={"select"}
                  options={categories.map((option) => {
                    return {
                      value: option.id,
                      label: option.name,
                    };
                  })}
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e);
                    setArticle({
                      ...article,
                      category_id: e.map((x) => x.value),
                    });
                  }}
                  placeholder={"Select Category"}
                />
              </div>
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Title
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Title"
                value={article.title}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    title: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Language
              </label>
              <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                <Select
                  className="w-full "
                  classNamePrefix={"select"}
                  options={languagesData}
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e);
                    setArticle({
                      ...article,
                      lang: e.value,
                    });
                  }}
                  placeholder={"Select Category"}
                />
              </div>
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Caption
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="caption"
                id="caption"
                placeholder="Caption"
                value={article.caption}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    caption: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Description
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                value={article.description}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    description: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Keywords
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="keywords"
                id="keywords"
                placeholder="Keywords"
                value={article.keywords}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    keywords: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Tags
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="tags"
                id="tags"
                placeholder="Tags"
                value={article.tags}
                onChange={(e) => {
                  setArticle({
                    ...article,
                    tags: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div>
              <label className="text-gray-800 text-sm font-bold leading-[140%]">
                Status
              </label>
              <div className="py-0.5 pl-2 mt-2 border border-line-grey rounded-lg flex items-center">
                <Select
                  className="w-full "
                  classNamePrefix={"select"}
                  options={statusData}
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e);
                    setArticle({
                      ...article,
                      is_publish: e.value,
                    });
                  }}
                  placeholder={"Select Status"}
                />
              </div>
            </div>
          </div>
          <div className="w-[100%] flex flex-col space-y-4">
            <div style={leftCard} className="w-full">
              <div className="flex flex-col items-center">
                {information.url ? (
                  <>
                    <div className="image-box rounded-[10px] overflow-hidden  mb-6">
                      <img
                        src={information.url}
                        alt="dummy profile "
                        className="w-full h-full object-cover object-center"
                      />
                    </div>
                  </>
                ) : (
                  <div className="image-box rounded-[10px] overflow-hidden h-[320px] w-[320px] xl:h-[320px] xl:w-[320px] mb-6 bg-gray-200 px-5 py-5">
                    <PhotoIcon className="w-full text-gray-400" />
                  </div>
                )}

                <div className="w-full">
                  {
                    <>
                      <button
                        className="order border-main-violate border w-full px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[46px] rounded-lg hover:bg-main-violate-100 disabled:border-gray-500 disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-100"
                        type=""
                        disabled={uploading}
                        onClick={(e) => {
                          e.preventDefault();
                          imageFileRef.current.click();
                        }}
                      >
                        {uploading ? <BtnSpinner /> : "Select Cover"}
                      </button>
                      <input
                        type="file"
                        className="h-full w-full opacity-0 cursor-pointer hidden"
                        name="uploadcv"
                        accept="image/*"
                        onChange={upload}
                        ref={imageFileRef}
                      />
                      <p className="text-sm text-grey-500  leading-[1.5] mb-1 mt-3">
                        File size: 10 Mb (maximum). Allowed file extensions:
                        .JPG .JPEG .PNG
                      </p>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-6 mb-4" />
        <div>
          <label className="text-gray-800 text-sm font-bold leading-[140%]">
            Content
          </label>
          <div className="h-[480px] mb-4 w-full mt-2">
            <ReactQuill
              theme="snow"
              value={article.content}
              onChange={(e) => {
                setArticle({
                  ...article,
                  content: e,
                });
              }}
              className="h-full w-full"
            />
          </div>
        </div>

        <hr className="mt-12 mb-2" />
        <div className="flex justify-end mt-3">
          {submitting ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              onClick={(e) => handleSubmit(e)}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogContentCreatePage;
