import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Avatar, Sidebar } from "flowbite-react";
import { fetchPermissions } from "../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import NavbarDashboard from "../Component/NavbarDashboard";
import PageSpinner from "../Component/PageSpinner";
import {
  BriefcaseIcon,
  UserGroupIcon,
  PresentationChartLineIcon,
  IdentificationIcon,
  BuildingOfficeIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
  ArrowRightOnRectangleIcon,
  Squares2X2Icon,
  ShoppingBagIcon,
  VideoCameraIcon,
  FolderPlusIcon,
  FolderIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";
import { fetchJobDepartments } from "../features/shared/jobDepartmentSlice";
import { fetchJobPositions } from "../features/shared/jobPositionSlice";
import { fetchNotifications } from "../features/notification/notificationSlice";
import config from "../config";
const DemoLayout = () => {
  const dispatch = useDispatch();
  const { data: notifications, loaded } = useSelector(
    (state) => state.notification
  );

  const navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);

  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || { base_role: null };
  });

  // const userData = JSON.parse(localStorage.getItem("user"));
  const [openSidebar, setOpenSidebar] = useState(true);
  const [loading, setLoading] = useState(true);
  const sidebar_packages = useRef(null);

  useEffect(() => {
    if (user.base_role === "superadmin") {
      navigate("/administrator/login", { replace: true });
    }
  }, [user]);

  useEffect(() => {
    if (!user.base_role) {
      navigate("/login", { replace: true });
    } else {
      dispatch(fetchPermissions());
      dispatch(fetchJobDepartments());
      dispatch(fetchJobPositions());
      dispatch(fetchNotifications());
    }
  }, []);

  useEffect(() => {
    if (permission) {
      setLoading(false);
    }
  }, [permission]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/client/packages")) {
      let element = document.getElementsByClassName("sidebar-packages");
      element[0]?.nextSibling?.removeAttribute("hidden");
    }
  }, []);

  // useEffect(() => {
  //   if(!openSidebar){
  //     document.body.classList.add("overflow-hidden")
  //   } else {
  //     document.body.classList.remove("overflow-hidden")
  //   }
  // }, [openSidebar])

  const signOut = () => {
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center h-screen">
  //       <PageSpinner />
  //       <p className="text-gray-500 text-sm font-semibold">Loading App...</p>
  //     </div>
  //   );
  // }
  return (
    <>
      <NavbarDashboard
        openSidebar={(e) => {
          setOpenSidebar(!openSidebar);
        }}
        notifications={notifications}
        loaded={loaded}
      />
      <Sidebar
        aria-label="Sidebar with multi-level dropdown"
        className="w-full sidebar"
      >
        <Sidebar.Items className="w-full md:flex mt-[70px]">
          <div
            className={`${
              (openSidebar ? " translate-x-[-100%] " : " translate-x-[0%] ") +
              "mdb:translate-x-[0%] w-[75%] sm:w-[50%] lg:w-[18%] mdb:w-[25%] lg:max-w-xs py-4 px-2 fixed mdb:relative  bg-white z-40 h-[93%] duration-150 ease-out lg:flex-1 flex flex-col"
            }`}
          >
            <div className="flex-1">
              {/* {!loading && ( */}
              <>
                <Sidebar.ItemGroup>
                  <p className="text-sm text-grey-400 font-semibold px-4">
                    Main Menu
                  </p>

                  <Sidebar.Item
                    className={`${
                      (window.location.pathname.startsWith("/client/demo/performance/dashboard")
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/client/demo/performance/dashboard");
                    }}
                    icon={Squares2X2Icon}
                    active={window.location.pathname.startsWith(
                      "/client/demo/performance/dashboard"
                    )}
                  >
                    <p className="text-grey-500">Dashboard</p>
                  </Sidebar.Item>
                  <Sidebar.Item
                    className={`${
                      (window.location.pathname.startsWith("/client/demo/performance/appraisals")
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/client/demo/performance/appraisals");
                    }}
                    icon={ClipboardDocumentListIcon}
                    active={window.location.pathname.startsWith(
                      "/client/demo/performance/appraisals"
                    )}
                  >
                    <p className="text-grey-500">Appraisals</p>
                  </Sidebar.Item>
                  <Sidebar.Item
                    className={`${
                      (window.location.pathname.startsWith("/client/demo/performance/templates")
                        ? " active "
                        : "") + "sidebar-item hover:cursor-pointer px-4"
                    }`}
                    onClick={(e) => {
                      navigate("/client/demo/performance/templates");
                    }}
                    icon={FolderIcon}
                    active={window.location.pathname.startsWith(
                      "/client/demo/performance/templates"
                    )}
                  >
                    <p className="text-grey-500">Templates</p>
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
              </>
              {/* )} */}
            </div>
            <div className="h-[65px] bg-gray-50 px-2 rounded-md block mdb:hidden">
              <div className="flex justify-between items-center h-full">
                <div className="flex space-x-4 h-full items-center ">
                  <Avatar
                    className=""
                    alt="User settings"
                    img={user?.company?.image}
                    rounded={true}
                  />
                  <div className="flex flex-col text-left">
                    <p className="text-sm font-semibold">
                      {user?.company?.name}
                    </p>
                    <p className="text-sm text-gray-500 font-medium">
                      {user.name}
                    </p>
                  </div>
                </div>
                <ArrowRightOnRectangleIcon
                  onClick={() => signOut()}
                  className="text-gray-500 hover-cursor-pointer cursor-pointer"
                  width={30}
                />
              </div>
            </div>
          </div>
          <div className="content w-[100%] mdb:w-[75%] sm:w-[100%] lg:w-[92%] overflow-x-hidden bg-[#F8F8F8] flex-1">
            {!openSidebar && (
              <>
                <div className="overlay fixed w-full bg-gray-500/[.5] h-full z-10"></div>
              </>
            )}

            <Outlet />
          </div>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
};

export default DemoLayout;
