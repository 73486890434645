import React, { useEffect, useState } from "react";
import "./index.css"; // Import CSS file for styling
import { getUtilityCandidateInJobPosition } from "../../api";
import FilterTable from "../Tables/FilterTable";
import Select from "react-select";
import { Avatar } from "flowbite-react";
import StatsTable2 from "../Tables/StatsTable2";
const ComparisonFloatingAdd = ({
  onApply,
  candidateIds = [],
  companyJobPositionId,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [newCandidateIds, setNewCandidateIds] = useState([]);
  const [totalChecked, setTotalChecked] = useState(0);
  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const handleAddCandidate = () => {
    onApply(candidates.filter((candidate) => candidate.checked));
    setShowPopup(false);
    setTotalChecked(candidates.filter((candidate) => candidate.checked).length);
  };

  const fetchCandidates = async () => {
    const response = await getUtilityCandidateInJobPosition(
      companyJobPositionId
    );

    if (response.success) {
      const candidates = response.data.map((candidate) => {
        return {
          ...candidate,
          checked: newCandidateIds.includes(candidate.id),
        };
      });
      setCandidates(candidates);
    }
  };

  const TableContent = ({ content }) => {
    return content.map((item, index) => (
      <>
        <tr className="whitespace-nowrap  bg-gray-50 rounded-lg">
          <td className="px-3 py-3 text-sm text-gray-900 mdb:max-w-[50px] font-semibold w-full">
            <div className="flex gap-4 items-center">
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-100"
                type="checkbox"
                checked={item.checked}
                onChange={() => {
                  const updatedCandidates = candidates.map((candidate) => {
                    if (candidate.id === item.id) {
                      return { ...candidate, checked: !candidate.checked };
                    }
                    return candidate;
                  });
                  setCandidates(updatedCandidates);
                }}
                disabled={
                  candidates.filter((candidate) => candidate.checked).length >= 5 &&
                  !item.checked
                }
              />
              <Avatar
                className="hidden mdb:block"
                alt="User settings"
                img={item.avatar}
                rounded={true}
              />
              {item.name}
            </div>
          </td>
          {/* <td className="py-4 text-sm text-gray-900 mdb:max-w-[100px] font-semibold mdb:w-[20%] truncate">
            {item.name}
          </td> */}
        </tr>
        <tr className="h-[8px]"></tr>
      </>
    ));
  };


  useEffect(() => {
    if (candidateIds.length > 0) {
      setTotalChecked(candidateIds.length);
      const updateNewCandidateIds = candidateIds.map((id) => id.split("-")[1]);
      setNewCandidateIds(updateNewCandidateIds);
    }
  }, [candidateIds]);

  useEffect(() => {
    if (companyJobPositionId && newCandidateIds.length > 0) {
      fetchCandidates();
    }
  }, [companyJobPositionId, newCandidateIds]);
  return (
    <div className="floating-button-container">
      <button className="floating-button" onClick={handleButtonClick}>
        VS | {totalChecked} Candidate Compare
      </button>
      {showPopup && (
        <div className="popup">
          <div className="flex flex-col">
            <p className="text-sm font-medium text-gray-500 px-[2px] mb-3 pt-1">Candidates</p>
            <div className="flex-1 min-w-[480px] min-h-[260px] max-h-[420px] overflow-y-auto ">
              <StatsTable2
                checkbox={false}
                headers={[]}
                content={<TableContent content={candidates} />}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="btn-main-violate max-w-nax mt-4 text-[10px] disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleAddCandidate}
                disabled={candidates.filter((candidate) => candidate.checked).length === 0}
              >
                Compare ({candidates.filter((candidate) => candidate.checked).length} / 5)
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparisonFloatingAdd;
