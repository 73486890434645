import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DocumentPlusIcon, VideoCameraIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import CreateSession from "./Sections/CreateSession";
import SelectCandidate from "./Sections/SelectCandidate";
import PreviewSession from "./Sections/PreviewSession";
import BtnSpinner from "../../../../../../Component/BtnSpinner";
import BreadCrumb from "../../../../../../Component/BreadCrumb";
import {
  createAppraisal,
  getAppraisalEmployeeInJobRole,
  getMySubscriptions,
  getUtilityAllClients,
  getUtilityAppraisalTemplates,
} from "../../../../../../api";
import PageSpinner from "../../../../../../Component/PageSpinner";
import config from "../../../../../../config";
import { documentTitle } from "../../../../../../helpers";
import Stepper from "./Stepper";
import moment from "moment";
import { useSelector } from "react-redux";

const AppraisalCreatePage = () => {
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  const permission = useSelector((state) => state.user.permissions);
  let navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [formPassed, setFormPassed] = useState(false);
  const [formPosition, setFormPosition] = useState(1);
  const [jobRole, setJobRole] = useState(null);
  const [template, setTemplate] = useState(null);
  const [filters, setFilters] = useState(null);
  const [jobRoles, setJobRoles] = useState([]);
  const [tests, setTests] = useState([]);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showCreateJobTokenPopup, setShowCreateJobTokenPopup] = useState(false);
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const [validating, setValidating] = useState(false);
  // const [checking, setChecking] = useState(true);
  const [createable, setCreateable] = useState(false);
  const [appraisalTemplates, setAppraisalTemplates] = useState([]);
  const [assessors, setAssessors] = useState([]);
  const [assessor, setAssessor] = useState(null);
  const [form, setForm] = useState({
    company_job_position_id: "",
    start_date: "",
    end_date: "",
    name: "",
    appraisal_template_id: "",
    assessor_client_id: "",
  });
  const [showProfile, setShowProfile] = useState(null);

  const getJobRoles = async () => {
    try {
      const response = await axios.get("/api/client/assessment/job-role-list");
      const result = response.data;
      if (result.success) {
        setJobRoles(result.data);
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getAppraisalTemplates = async () => {
    const response = await getUtilityAppraisalTemplates();
    if (response.success) {
      setAppraisalTemplates(response.data);
    } else {
      toast.error(response.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const getAssessors = async () => {
    const response = await getUtilityAllClients();
    if (response.success) {
      setAssessors(response.data);
    } else {
      toast.error(response.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    documentTitle("Create Jobs");
    getJobRoles();
    getAppraisalTemplates();
    getAssessors();
  }, []);

  useEffect(() => {
    if (formPosition == 1) {
      if (
        form.start_date != "" &&
        form.end_date != "" &&
        form.company_job_position_id != "" &&
        form.name != "" && 
        form.appraisal_template_id != "" &&
        form.assessor_client_id != ""
      ) {
        setFormPassed(true);
      } else {
        setFormPassed(false);
      }
    }
  }, [form, formPosition]);

  const handleSelectedTest = (candidate) => {
    setSelectedCandidates([...selectedCandidates, candidate]);
  };

  useEffect(() => {
    if (selectedCandidates.length > 0) {
      setFormPassed(true);
    } else {
      setFormPassed(false);
    }
  }, [selectedCandidates]);
  const handleRemoveTest = (candidate) => {
    // REMOVE CANDIDATE
    setSelectedCandidates(
      selectedCandidates.filter((item) => item.id != candidate.id)
    );
  };

  const handlNextSection = () => {
    if (formPosition == 1) {
      setCalendars([]);
    }
    setFormPosition(formPosition + 1);
  };

  const handleCreateJob = () => {
    // TEMPORARY FOR TEST
    // ENV DEV
    const isDev = config.APP_ENV == "development";
    if (isDev) {
      if (mySubscriptions.length > 0) {
        setCreateable(false);
        setShowCreateJobTokenPopup(true);
      } else {
        setCreateable(true);
        // navigate('/client/jobs/create')
      }
    } else {
      setCreateable(false);
      setShowCreateJobTokenPopup(true);
    }
  };

  const handleShowProfile = (profile) => {
    setShowProfile(profile);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    const santizeForm = {
      company_job_position_id: form.company_job_position_id,
      name: form.name,
      start_date: form.start_date,
      end_date: form.end_date,
      status: 1,
      appraisal_template_id: form.appraisal_template_id,
      employee_ids: selectedCandidates.map((candidate) => candidate.id),
      assessor_client_id: form.assessor_client_id
    };
    const result = await createAppraisal(santizeForm);
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => navigate("/client/demo/performance/appraisals"),
      });
    } else {
      setSubmitting(false);
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const getCandidateInPosition = async () => {
    const result = await getAppraisalEmployeeInJobRole(
      form.company_job_position_id
    );

    if (result.success) {
      setCandidates(result.data.data);
      setFilters(result.additional.filter);
    } else {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    handleCreateJob();
  }, [mySubscriptions]);

  useEffect(() => {
    if (jobRole) {
      getCandidateInPosition();
    }
  }, [jobRole]);

  useEffect(() => {
    if (formPosition == 2) {
      if (calendars.length > 0) {
        if (
          calendars
            .filter((calendar) => calendar)
            .some((calendar) => calendar.events.length > 0)
        ) {
          setFormPassed(true);
        } else {
          setFormPassed(false);
        }
      }
    }
  }, [calendars]);

  // useEffect(() => {
  //   if(quotaTest != null && quotaTest == 0){

  //   }
  // }, [quotaTest]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <DocumentPlusIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Create Appraisal</p>
          <BreadCrumb
            list={[
              {
                href: "/client/demo/performance/appraisal",
                title: "Appraisals",
              },
              { href: null, title: "Create Appraisal" },
            ]}
          />
        </div>
      </div>
      <Stepper formPosition={formPosition} />
      <div className="mb-6">
        {formPosition == 1 && (
          <>
            <CreateSession
              jobRoles={jobRoles}
              jobRole={jobRole}
              name={form.name}
              startDate={form.start_date}
              endDate={form.end_date}
              templates={appraisalTemplates}
              template={template}
              assessors={assessors}
              assessor={assessor}
              onJobRoleChange={(value) => {
                setForm({
                  ...form,
                  company_job_position_id: value.value,
                });
                setJobRole(value);
              }}
              onTemplateChange={(value) => {
                setForm({
                  ...form,
                  appraisal_template_id: value.value,
                });
                setTemplate(value);
              }}
              onAssessorChange={(value) => {
                setForm({
                  ...form,
                  assessor_client_id: value.value,
                });
                setAssessor(value);
              }}
              onNameChange={(value) => setForm({ ...form, name: value })}
              onStartDateChange={(value) =>
                setForm({ ...form, start_date: value })
              }
              onEndDateChange={(value) => setForm({ ...form, end_date: value })}
            />
          </>
        )}
        {formPosition == 2 && (
          <>
            <SelectCandidate
              filters={filters}
              selectedCandidates={selectedCandidates}
              candidates={candidates}
              tests={tests}
              onSelect={handleSelectedTest}
              // onShowProfile={handleShowProfile}
              onRemove={handleRemoveTest}
              filterAssessment={filterAssessment}
            />
          </>
        )}
        {formPosition == 3 && (
          <PreviewSession
            template={template}
            form={form}
            assessor={assessor}
            selectedCandidates={selectedCandidates}
            role={jobRole}
            tests={tests.filter((test) => test.selected == true)}
            onDelete={(e) => handleRemoveTest(e)}
            onShowProfile={handleShowProfile}
          />
        )}
        <div className="flex justify-end">
          {formPosition > 1 ? (
            <>
              <button
                className={`${
                  (submitting ? "hidden " : "") +
                  "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                }`}
                onClick={(e) => {
                  setFormPosition(formPosition - 1);
                }}
              >
                Previous
              </button>
            </>
          ) : (
            <>
              <button
                className={`${
                  (submitting ? "hidden " : "") +
                  "mr-4 border border-line-grey-1 w-[160px] px-4 text-[16px] leading-[22.4px] text-grey-98 font-semibold h-[46px] rounded-lg"
                }`}
                type="submit"
                onClick={(e) => {
                  navigate("/client/interviews");
                }}
              >
                Cancel
              </button>
            </>
          )}

          {submitting ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={!formPassed}
              onClick={(e) => {
                if (formPosition < 3) {
                  handlNextSection();
                } else {
                  handleSubmit();
                }
              }}
            >
              {formPosition < 3 ? "Next" : "Finish & Submit"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppraisalCreatePage;
