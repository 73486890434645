import React from "react";
import { useState, useEffect } from "react";
// import RangeList2 from "../Components/RangeList2";
import CustomRadarChart from "../../../../../Component/CustomRadarChart";
import { normalizeToPercentage } from "../../../../../helpers";
const ComparisonPersonalityRadarChart = ({ data, onSelectCandidate }) => {
  const [results, setResults] = useState(null);
  const [oneResults, setOneResults] = useState([]);
  const [topResult, setTopResult] = useState(null);
  const [remapGroup, setRemapGroup] = useState([]);
  const [filteredRemapGroup, setFilteredRemapGroup] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const colorRanges = [
    {
      gr1: "rgba(60, 89, 252, 0.7)", // #3c59fc
      gr2: "rgba(118, 0, 224, 0.7)", // #7600e0
    },
    {
      gr1: "rgba(60, 89, 252, 0.7)", // #3c59fc
      gr2: "rgba(118, 0, 224, 0.7)", // #7600e0
    },
    {
      gr1: "rgba(60, 89, 252, 0.7)", // #3c59fc
      gr2: "rgba(118, 0, 224, 0.7)", // #7600e0
    },
    {
      gr1: "rgba(255, 205, 0, 0.7)", // #ffcd00
      gr2: "rgba(255, 86, 49, 0.7)", // #ff5631
    },
    {
      gr1: "rgba(0, 254, 176, 0.7)", // #00feb0
      gr2: "rgba(57, 195, 224, 0.7)", // #39c3e0
    },
  ];

  useEffect(() => {
    if (data) {
      let values = [];
      let candidates = [];

      values = data.map((item, key) => {
        return {
          label: item.candidate.fullname,
          data: item.result.map((res, key2) => {
            return {
              name: res.test_name,
              category:
                res.test === "professional-behaviour"
                  ? res.result.map((r) => r.category)
                  : res.result[0].data.map((r) => r.name),
              data:
                res.test === "professional-behaviour"
                  ? res.result.map((r) => {
                      const totalMaxValue = r.data
                        .map((d) => parseInt(d.max_value))
                        .reduce((a, b) => a + b, 0);
                      const totalMinValue = r.data
                        .map((d) => parseInt(d.min_value))
                        .reduce((a, b) => a + b, 0);
                      const totalResult = r.data
                        .map((d) => d.result)
                        .reduce((a, b) => a + b, 0);
                      return parseInt(
                        normalizeToPercentage(
                          totalResult,
                          totalMinValue,
                          totalMaxValue
                        )
                      );
                    })
                  : res.result[0].data.map((r) => {
                      return parseInt(
                        normalizeToPercentage(
                          r.result,
                          r.min_value,
                          r.max_value
                        )
                      );
                    }),
              backgroundColor: ({ chart }) => {
                const ctx = chart.ctx;
                const width = 200; // lebar kanvas
                const height = 200; // tinggi kanvas
                const degree = 134; // sudut gradien dalam derajat

                // Konversi derajat ke radian
                const radian = degree * (Math.PI / 180);

                // Titik pusat kanvas (opsional, tergantung di mana Anda mulai)
                const centerX = width / 2;
                const centerY = height / 2;

                // Panjang relatif gradien (untuk menentukan seberapa jauh titik akhir)
                const length = Math.sqrt(width * width + height * height);

                // Koordinat titik awal (bisa disesuaikan, misalnya di pojok kiri atas)
                const x0 = 0;
                const y0 = 0;

                // Koordinat titik akhir menggunakan trigonometri
                const x1 = centerX + length * Math.cos(radian);
                const y1 = centerY + length * Math.sin(radian);

                if (!isNaN(x1) && !isNaN(y1)) {
                  const gradient = ctx.createLinearGradient(x0, y0, x1, y1);
                  gradient.addColorStop(0, colorRanges[key2].gr1);
                  gradient.addColorStop(1, colorRanges[key2].gr2);
                  return gradient;
                }
              },
              borderColor: colorRanges[key2].gr1,
              borderWidth: 1,
              hidden: "",
              pointBorderColor: colorRanges[key2].gr1, // Set the point border color
              pointBorderWidth: 3, // Set the point border width
            };
          }),
        };
      });

      candidates = values.map((item) => item.label);
      setCandidates(candidates);

      setResults(values);
      setActiveTab(values[0].label);
    }
  }, [data]);

  useEffect(() => {
    if (activeTab && results) {
      let selectedResult = results.find((item) => item.label === activeTab);
      const remap = selectedResult.data.map((item) => {
        return {
          labels: item.category,
          datasets: [
            {
              label: item.name,
              data: item.data,
              backgroundColor: item.backgroundColor,
              borderColor: item.borderColor,
              borderWidth: item.borderWidth,
              hidden: item.hidden,
              pointBorderColor: item.pointBorderColor,
              pointBorderWidth: item.pointBorderWidth,
            },
          ],
        };
      });
      setOneResults(remap);
      onSelectCandidate(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (remapGroup.length > 0 && topResult) {
      const filteredRemapGroup = remapGroup.filter((item) => {
        const topCandidate = item.candidates.find(
          (candidate) => candidate.name === topResult.name
        );
        const hasLowerResult = item.candidates.some(
          (candidate) =>
            candidate.name !== topResult.name &&
            candidate.result_percentage < topCandidate.result_percentage
        );
        return topCandidate && hasLowerResult;
      });
      setFilteredRemapGroup(filteredRemapGroup);
    }
  }, [remapGroup, topResult]);
  return (
    <div className="mt-3">
      <div className="mb-6">
        <nav className="border-b border-1 rounded-md p-2">
          <div className="flex space-x-4 overflow-x-auto  ">
            {candidates.map((candidate) => (
              <a
                href="#"
                className={`${
                  activeTab === candidate
                    ? "text-[#0384EDFF] py-2 px-4 font-semibold bg-[#F3FFFBFF] rounded-lg flex-shrink-0 border border-1"
                    : "text-gray-500 hover:text-indigo-600 py-2 px-4 font-semibold transition duration-200 flex-shrink-0 border border-1 rounded-lg"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(candidate);
                }}
              >
                {candidate}
              </a>
            ))}
          </div>
        </nav>
      </div>
      <div className="flex flex-col items-center ">
        <div className="flex w-full mb-7 mdb:mb-5 gap-10 px-2 mdb:px-4 flex-col mdb:flex-row">
          {/* <div className="w-full flex justify-center mdb:w-[50%]"> */}
          <div className="w-full flex gap-[120px] justify-center ">
            {oneResults.length > 0 && (
              <>
                {oneResults.map((item, key) => (
                  <div className="max-w-[420px] w-full h-full flex-shrink-0">
                    <CustomRadarChart data={item} maxValue={100} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonPersonalityRadarChart;
