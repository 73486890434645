import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  InformationCircleIcon,
  UserGroupIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import PaginationTable from "../../../Component/Tables/PaginationTable";
import axios from "axios";
import TableSpinner from "../../../Component/TableSpinner";
import DropdownDots from "../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import EmptyContent from "../../../Component/Tables/EmptyContent";
import {
  documentTitle,
  inputIsEmailFormat,
  inputIsOnlyAlphabetic,
} from "../../../helpers";
import SearchTable from "../../../Component/Tables/SearchTable";
import { getMyCandidates } from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import { setCandidates } from "../../../features/candidate/candidatesSlice";
import FilterTable from "../../../Component/Tables/FilterTable";
import BreadCrumb from "../../../Component/BreadCrumb";
import BtnSpinner from "../../../Component/BtnSpinner";
const MyCandidatesPage = () => {
  const permission = useSelector((state) => state.user.permissions);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const {
    data: candidates,
    itemsPerPage,
    itemsFrom,
    itemsTo,
    totalItems,
    currentPage,
    loaded,
    filterPositions,
    filterStatus,
    filterEducation,
  } = useSelector((state) => state.candidates.candidatesTable);
  const [tableCandidateLoading, setTableCandidateLoading] = useState(1);
  // const [candidates, setCandidates] = useState([])
  const [formPassed, setFormPassed] = useState(false);
  const [query, setQuery] = useState(null);
  const [, setPositions] = useState();
  const [form, setForm] = useState({
    email: "",
    name: "",
  });
  const [creating, setCreating] = useState(false);
  const [selectedFilterPosition, setSelectedFilterPosition] = useState(null);
  const [selectedFilterStatus, setSeletectedFilterStatus] = useState(null);
  const [selectedFilterEducation, setSelectedFilterEducation] = useState(null);
  const [stickyContents, setStickyContents] = useState([]);
  const fetchMyCandidate = async (page = 1) => {
    setTableCandidateLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getMyCandidates(queryString);
    dispatch(setCandidates(result));
    setTableCandidateLoading(false);
  };

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };
  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const createCandidate = async (e) => {
    e.preventDefault();
    if (!permission?.job_role?.includes("create")) {
      alert(
        "You don't have permission to create candidate. Please contact administrator."
      );
      return;
    }
    setCreating(true);
    try {
      const response = await axios.post(
        "/api/client/candidates",
        JSON.stringify(form),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = response.data;
      if (result.success) {
        toast.success("Create candidate success", {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
        });
        setForm({ ...form, name: "", email: "" });
        getMyCandidates();
      } else {
        toast.error(result.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setCreating(false);
  };

  const handleFilterPosition = (id) => {
    setQuery({ ...query, position: id });
    setSelectedFilterPosition(id);
  };

  const handleFilterStatus = (status) => {
    setQuery({ ...query, status: status });
    setSeletectedFilterStatus(status);
  };

  const handleFilterEducation = (id) => {
    setQuery({ ...query, educational_degree: id });
    setSelectedFilterEducation(id);
  };

  const handleClearFilter = (e) => {
    setSelectedFilterPosition("");
    setSelectedFilterEducation("");
    setSeletectedFilterStatus("");
    setQuery({ ...query, position: "", status: "", educational_degree: "" });
  };

  const handleDetailCandidate = (id, candidate_job_id) => {
    // alert(id);
    navigate(`/client/my-candidates/${id}/${candidate_job_id}`);
  };

  useEffect(() => {
    fetchMyCandidate();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0 || status == 3) {
      return (
        <p className="bg-red-100 text-red-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 2) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };

  const StickyContent = () => {
    if (!tableCandidateLoading) {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr className="whitespace-nowrap border-b-[1px] ">
                    <td className="px-6 py-[18.4px]">
                      <DropdownDots>
                        <Dropdown.Item
                          onClick={(e) =>
                            handleDetailCandidate(
                              candidate.id,
                              candidate.candidate_job_id
                            )
                          }
                        >
                          <InformationCircleIcon
                            color="#6B7280"
                            width={16}
                            className="mr-2"
                          />{" "}
                          Detail
                        </Dropdown.Item>
                      </DropdownDots>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  const TableCandidateContent = () => {
    if (tableCandidateLoading) {
      return <TableSpinner colSpan={"100%"} />;
    } else {
      return (
        <>
          {candidates.length > 0 ? (
            <>
              {candidates.map((candidate, index) => {
                return (
                  <tr
                    className="whitespace-nowrap border-b-[1px] hover:bg-line-grey cursor-pointer"
                    onClick={(e) =>
                      handleDetailCandidate(
                        candidate.id,
                        candidate.candidate_job_id
                      )
                    }
                  >
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {itemsPerPage * (currentPage - 1) + index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.name}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.job ? candidate.job.position : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      {candidate.job ? (
                        <>
                          <StatusLabel
                            status={candidate.job.status}
                            label={candidate.job.status_label}
                          />
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {moment
                          .duration(
                            candidate.personal_detail.work_experience,
                            "months"
                          )
                          .years() +
                          " Years, " +
                          moment
                            .duration(
                              candidate.personal_detail.work_experience,
                              "months"
                            )
                            .months() +
                          " Months"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.personal_detail.educational
                          ? candidate.personal_detail.educational
                              .educational_degree.name
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.personal_detail.educational
                          ? candidate.personal_detail.educational
                              .educational_major.name
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {candidate.personal_detail.educational
                          ? candidate.personal_detail.educational.institution
                          : "-"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {moment(candidate.created_at).format("MMM D, YYYY")}
                      </div>
                    </td>
                    {/* <td className="px-6 py-4 sticky">
                    <DropdownDots>
                      <Dropdown.Item onClick={(e) => handleDetailCandidate(candidate.id, candidate.candidate_job_id)}>
                        <InformationCircleIcon color='#6B7280' width={16} className='mr-2' /> Detail
                      </Dropdown.Item>
                    </DropdownDots>
                  </td> */}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <EmptyContent />
            </>
          )}
        </>
      );
    }
  };

  const validateForm = () => {
    if (form.email != "" && form.name != "") {
      if (!inputIsEmailFormat(form.email)) {
        setFormPassed(false);
      } else {
        setFormPassed(true);
      }
    } else {
      setFormPassed(false);
    }
  };

  useEffect(() => {
    validateForm();
  }, [form]);
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });

  useEffect(() => {
    documentTitle("My Candidates");
  }, []);

  const myStyle = {
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <UserGroupIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">My Candidates</p>
          <BreadCrumb list={[{ href: null, title: "My Candidates" }]} />
        </div>
      </div>
      <div className="invite-section mb-6">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-5">
            <div>
              <p className="text-lg text-grey-600 font-bold mb-2">
                Create new candidate
              </p>
            </div>
          </div>
          <div>
            <form
              className="bg-grey-lightest"
              name="create_candidate"
              onSubmit={createCandidate}
            >
              <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
                <div className="mb-2">
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Candidate Name
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Candidate fullname"
                    value={form.name}
                    onChange={(e) => {
                      if (inputIsOnlyAlphabetic(e.target.value)) {
                        setForm({ ...form, name: e.target.value });
                      }
                    }}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label className="text-gray-800 text-sm font-bold leading-[140%]">
                    Candidate Email
                  </label>
                  <input
                    className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Candidate active email"
                    value={form.email}
                    onChange={(e) => {
                      setForm({ ...form, email: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <button
                className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                type="submit"
                disabled={!formPassed || creating}
              >
                {creating ? (
                  <>
                    <BtnSpinner />
                  </>
                ) : (
                  <>Create</>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="candidate-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Candidate List
              </p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
          </div>
          <div className="border-b border-gray-200 shadow">
            <PaginationTable
              filters={[
                <FilterTable
                  selected={selectedFilterPosition}
                  placeholder={"Select Position"}
                  options={filterPositions}
                  onChange={(value) => handleFilterPosition(value)}
                />,
                <FilterTable
                  selected={selectedFilterStatus}
                  placeholder={"Select Status"}
                  options={filterStatus}
                  onChange={(value) => handleFilterStatus(value)}
                />,
                <FilterTable
                  selected={selectedFilterEducation}
                  placeholder={"Select Education Level"}
                  options={filterEducation}
                  onChange={(value) => handleFilterEducation(value)}
                />,
              ]}
              isSticky={true}
              onClearFilter={(e) => handleClearFilter()}
              search={
                <SearchTable
                  onSearch={(keyword) => handleSearch(keyword)}
                  placeholder={"Search Candidate Name"}
                />
              }
              checkbox={false}
              currentPage={handlePaginate}
              itemsTo={itemsTo}
              itemsFrom={itemsFrom}
              itemsPerPage={5}
              totalItems={totalItems}
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              headers={[
                "NO",
                "FULLNAME",
                "JOB POSITION",
                "STATUS",
                "WORK EXPERIENCE",
                "EDU. LEVEL",
                "EDU. MAJOR",
                "UNIVERSITY",
                "INVITED ON",
              ]}
              content={<TableCandidateContent />}
              stickyHeader={["ACTION"]}
              stickyContent={<StickyContent />}
            />
          </div>
          {/* <UnderDevelopment /> */}
        </div>
      </div>
    </div>
  );
};

export default MyCandidatesPage;
