import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  PlusCircleIcon,
  CheckCircleIcon,
  NewspaperIcon,
  PauseCircleIcon,
  EyeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import BreadCrumb from "../../../../Component/BreadCrumb";
import PaginationTable from "../../../../Component/Tables/PaginationTable";
import SearchTable from "../../../../Component/Tables/SearchTable";
import FilterTable from "../../../../Component/Tables/FilterTable";
import TableSpinner from "../../../../Component/TableSpinner";
import EmptyContent from "../../../../Component/Tables/EmptyContent";
import { documentTitle } from "../../../../helpers";
import {
  getArticleCategories,
  getArticleContents,
  updateArticlePublish,
} from "../../../../api/admin";
import DropdownDots from "../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import moment from "moment";
import config from "../../../../config";

const BlogContentPage = () => {
  let navigate = useNavigate();
  const [query, setQuery] = useState({});
  const [articles, setArticles] = useState([]);
  const [tableLoading, setTableLoading] = useState(1);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterStatus, setFilterStatus] = useState([
    { value: "draft", title: "Draft" },
    { value: "publish", title: "Published" },
  ]);
  const [filterLanguage, setFilterLanguage] = useState([
    { value: "id", title: "Indonesia" },
    { value: "en", title: "English" },
  ]);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
  const [selectedFilterLanguage, setSelectedFilterLanguage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [itemsFrom, setItemsFrom] = useState(1);
  const [itemsTo, setItemsTo] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  // TEST DEPLOY & BUILD
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });
  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/administrator/login", { replace: true });
    }
  });

  const handleSearch = (keyword) => {
    setQuery({ ...query, search: keyword });
  };

  const handlePaginate = (page) => {
    setQuery({ ...query, page: page });
  };

  const fetchArticleCategory = async () => {
    const result = await getArticleCategories("limit=1000");
    if (result.success) {
      setFilterCategory(
        result.data.data.map((category) => {
          return {
            value: category.id,
            title: category.name,
          };
        })
      );
    }
  };
  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectedFilterCategory("");
    setSelectedFilterStatus("");
    setSelectedFilterLanguage("");
    setQuery({ ...query, category: "", lang: "", status: "", search: "" });
  };

  const fetchPackageTransaction = async () => {
    setTableLoading(true);
    const queryString = new URLSearchParams(query).toString();
    const result = await getArticleContents(queryString);
    if (result.success) {
      setArticles(result.data.data);
      setCurrentPage(result.data.current_page);
      setTotalItems(result.data.total);
      setItemsPerPage(result.data.per_page);
      setItemsFrom(result.data.from);
      setItemsTo(result.data.to);
      setItemsPerPage(result.data.per_page);
      setTableLoading(false);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
    }
  };

  const setUpdatePublish = async (id, status) => {
    const result = await updateArticlePublish(id, status);
    if (result.success) {
      toast.success(result.message, {
        toastId: "signup-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      // UPDATE ARTICLE PUBLISH BY ID
      let newArticles = articles.map((article) => {
        if (article.id == id) {
          article.is_publish = status;
          article.is_publish_label = status == 1 ? "Published" : "Draft";
        }
        return article;
      });

      setArticles(newArticles);
    } else {
      toast.error(result.message, {
        toastId: "signup-failure-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
      });
      setTableLoading(false);
    }
  };

  const handleFilterCategory = (value) => {
    setSelectedFilterCategory(value);
    setQuery({ ...query, category: value });
  };
  const handleFilterStatus = (value) => {
    setSelectedFilterStatus(value);
    setQuery({ ...query, status: value });
  };
  const handleFilterLanguage = (value) => {
    setSelectedFilterLanguage(value);
    setQuery({ ...query, lang: value });
  };

  useEffect(() => {
    documentTitle("Blog Management");
  }, []);
  useEffect(() => {
    fetchArticleCategory();
    fetchPackageTransaction();
  }, [query]);

  const StatusLabel = ({ status = 0, label = "" }) => {
    if (status == 0) {
      return (
        <p className="bg-yellow-100 text-yellow-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    } else if (status == 1) {
      return (
        <p className="bg-green-100 text-green-800 font-medium text-center text-xs py-1 rounded text px-2">
          {label}
        </p>
      );
    }
  };
  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {articles.length > 0 ? (
            articles.map((value, index) => {
              return (
                <tr key={value.id} className="whitespace-nowrap border-b-[1px]">
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 max-w-[400px] overflow-x-hidden truncate">
                    {value?.title}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900">
                    {/* {value?.category?.name} */}
                    {value?.category?.map((cat, index) => {
                      return (
                        <span
                          key={index}
                          className="bg-gray-100 text-gray-800 font-medium text-center text-xs py-1 rounded text px-2 mr-2"
                        >
                          {cat.name}
                        </span>
                      );
                    })}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 max-w-[320px] overflow-x-hidden truncate">
                    {value?.tags?.map((tag, index) => {
                      return (
                        <span
                          key={index}
                          className="bg-gray-100 text-gray-800 font-medium text-center text-xs py-1 rounded text px-2 mr-2"
                        >
                          {tag}
                        </span>
                      );
                    })}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {value?.lang}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <StatusLabel
                      status={value.is_publish}
                      label={value.is_publish_label}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {moment(value?.created_at).format("DD MMM YYYY HH:mm")}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900">
                    <DropdownDots>
                      {/* {value.is_publish == 0 && (
                        <Dropdown.Item
                          onClick={() => {
                            window.open(
                              `${config.WEB_URL}/articles/${value.slug}`,
                              "_blank"
                            );
                          }}
                        >
                          <EyeIcon width={16} className="mr-2" />
                          Preview Article
                        </Dropdown.Item>
                      )} */}
                      {value.is_publish == 1 && (
                        <Dropdown.Item
                          onClick={() => {
                            window.open(
                              `${config.WEB_URL}/articles/${value.slug}`,
                              "_blank"
                            );
                          }}
                        >
                          <EyeIcon width={16} className="mr-2" />
                          View Article
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={() => {
                          navigate(
                            `/administrator/blog-content/edit/${value.id}`
                          );
                        }}
                      >
                        <PencilSquareIcon width={16} className="mr-2" />
                        Edit
                      </Dropdown.Item>
                      {value.is_publish == 0 && (
                        <Dropdown.Item
                          onClick={() => {
                            setUpdatePublish(value.id, 1);
                          }}
                        >
                          <CheckCircleIcon width={16} className="mr-2" />
                          Set Publish
                        </Dropdown.Item>
                      )}
                      {value.is_publish == 1 && (
                        <Dropdown.Item
                          onClick={() => {
                            setUpdatePublish(value.id, 0);
                          }}
                        >
                          <PauseCircleIcon width={16} className="mr-2" />
                          Set Unpublish
                        </Dropdown.Item>
                      )}
                    </DropdownDots>
                  </td>
                </tr>
              );
            })
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <NewspaperIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">Blog</p>
          <BreadCrumb list={[{ href: null, title: "Blog" }]} />
          {/* <p className='text-base text-grey-400 font-medium '>List of positions that have been entered</p> */}
        </div>
      </div>

      <div className="card flex flex-col w-full">
        <div className="flex justify-between w-full mb-6">
          <div className="flex flex-col">
            <p className="text-xl text-grey-600 font-bold mb-2">Blog Data</p>
            {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
          </div>
          <div className="flex justify-end">
            <button
              className="flex items-center justify-center bg-main-violate px-3 py-2 text-[16px] leading-[22.4px] text-white font-semibold rounded-lg hover:bg-violet-600 transition-colors duration-200 focus:outline-none disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              onClick={() => {
                navigate("/administrator/blog-content/create");
              }}
            >
              <PlusCircleIcon width={20} className="mr-2" /> Add Content
            </button>
          </div>
        </div>
        <div className="border-b border-gray-200 rounded-lg shadow">
          <PaginationTable
            search={
              <SearchTable
                onSearch={(keyword) => handleSearch(keyword)}
                placeholder={"Search title"}
              />
            }
            filters={[
              <FilterTable
                placeholder={"Select Topic"}
                selected={selectedFilterCategory}
                options={filterCategory}
                onChange={(value) => handleFilterCategory(value)}
              />,
              <FilterTable
                placeholder={"Select Status"}
                selected={selectedFilterStatus}
                options={filterStatus}
                onChange={(value) => handleFilterStatus(value)}
              />,
              <FilterTable
                placeholder={"Select Language"}
                selected={selectedFilterLanguage}
                options={filterLanguage}
                onChange={(value) => handleFilterLanguage(value)}
              />,
            ]}
            onClearFilter={(e) => handleClearFilter(e)}
            currentPage={handlePaginate}
            itemsTo={itemsTo}
            itemsFrom={itemsFrom}
            itemsPerPage={5}
            checkbox={false}
            totalItems={totalItems}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            headers={[
              "NO.",
              "TITLE",
              "CATEGORY",
              "TAGS",
              "LANGUAGE",
              "PUBLISH",
              "CREATED AT",
              "ACTION",
            ]}
            content={<TableContent />}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogContentPage;
