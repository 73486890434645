import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { createTemplate, updateTemplate } from "../../../../../api";
import { ToastContainer, toast } from "react-toastify";
import BtnSpinner from "../../../../../Component/BtnSpinner";
const PerformanceReviewForm = ({
  mode = "create",
  data = null,
  templateId = null,
}) => {
  const [aspects, setAspects] = useState([
    { name: "", indicators: [{ name: "", weight: "", target: "" }] },
  ]);

  const [submitting, setSubmitting] = useState(false);

  const [name, setName] = useState("");

  const handleAddAspect = () => {
    setAspects([
      ...aspects,
      { name: "", indicators: [{ name: "", weight: "", target: "" }] },
    ]);
  };

  const handleAspectChange = (index, value) => {
    const updatedAspects = [...aspects];
    updatedAspects[index].name = value;
    setAspects(updatedAspects);
  };

  const handleIndicatorChange = (aspectIndex, indicatorIndex, field, value) => {
    const updatedAspects = [...aspects];
    updatedAspects[aspectIndex].indicators[indicatorIndex][field] = value;
    setAspects(updatedAspects);
  };

  const handleAddIndicator = (aspectIndex) => {
    const updatedAspects = [...aspects];
    updatedAspects[aspectIndex].indicators.push({
      name: "",
      weight: "",
      target: "",
    });
    setAspects(updatedAspects);
  };

  const handleRemoveIndicator = (aspectIndex, indicatorIndex) => {
    const updatedAspects = [...aspects];
    updatedAspects[aspectIndex].indicators.splice(indicatorIndex, 1);
    setAspects(updatedAspects);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const sanitizeForm = {
      name,
      aspects: aspects.map((aspect) => ({
        name: aspect.name,
        indicators: aspect.indicators.map((indicator) => ({
          name: indicator.name,
          weight: indicator.weight,
          target: indicator.target,
        })),
      })),
    };

    let result = null;
    if (mode === "edit") {
      result = await updateTemplate(templateId, sanitizeForm);
    } else {
      result = await createTemplate(sanitizeForm);
    }

    setSubmitting(false);
    if (!result.success) {
      toast.error(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.success(result.message, {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleRemoveAspect = (aspectIndex) => {
    setAspects((prevAspects) =>
      prevAspects.filter((_, index) => index !== aspectIndex)
    );
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setAspects(data.aspects);
    }
  }, [data]);

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit} className="max-w-4xl px-2 pt-2">
        <h2 className="text-xl font-bold mb-6">Appraisal Template</h2>
        <div className="mb-6 flex flex-col">
          <label htmlFor="name" className="text-sm font-semibold text-grey-98">
            {" "}
            Form/Template Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ex: Mid-Year Performance Review"
            className="border w-full mt-2 p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed max-w-[400px]"
          />
        </div>
        <hr className="my-6" />
        <label htmlFor="name" className="text-sm font-semibold text-grey-98">
          {" "}
          Aspects & Indicators
        </label>
        {aspects.map((aspect, aspectIndex) => (
          <div
            key={aspectIndex}
            className="mb-6 bg-gray-100 p-3 rounded-lg mt-3"
          >
            <div className="flex items-center mb-4 justify-between">
              <input
                type="text"
                value={aspect.name}
                onChange={(e) =>
                  handleAspectChange(aspectIndex, e.target.value)
                }
                placeholder="Aspect Name"
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
              />
              <button
                type="button"
                onClick={() => handleRemoveAspect(aspectIndex)}
                className=" px-3 py-1 text-white w-[40px]"
              >
                <TrashIcon className="w-5 h-5 text-red-600" />
              </button>
            </div>

            {aspect.indicators.map((indicator, indicatorIndex) => (
              <div
                key={indicatorIndex}
                className="mb-4 bg-gray-50 p-3 rounded-lg"
              >
                <div className="flex gap-2">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center flex-1">
                    <input
                      type="text"
                      value={indicator.name}
                      onChange={(e) =>
                        handleIndicatorChange(
                          aspectIndex,
                          indicatorIndex,
                          "name",
                          e.target.value
                        )
                      }
                      placeholder="Indicator Name"
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    />
                    <input
                      type="number"
                      value={indicator.weight}
                      onChange={(e) =>
                        handleIndicatorChange(
                          aspectIndex,
                          indicatorIndex,
                          "weight",
                          e.target.value
                        )
                      }
                      placeholder="Weight"
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    />
                    <input
                      type="number"
                      value={indicator.target}
                      onChange={(e) =>
                        handleIndicatorChange(
                          aspectIndex,
                          indicatorIndex,
                          "target",
                          e.target.value
                        )
                      }
                      placeholder="Target (%)"
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      handleRemoveIndicator(aspectIndex, indicatorIndex)
                    }
                    className=" px-3 py-1 text-white w-[40px]"
                  >
                    <TrashIcon className="w-5 h-5 text-red-600" />
                  </button>
                </div>
              </div>
            ))}

            {/* Add Indicator Button */}
            <button
              className="flex items-center justify-center bg-[#3F83F8E0] px-2 py-1 text-[14px] leading-[22.4px] text-white font-semibold rounded-full transition-colors duration-200 focus:outline-none disabled:cursor-not-allowed"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleAddIndicator(aspectIndex);
              }}
            >
              <PlusIcon width={16} className="mr-1" /> Indicator
            </button>
          </div>
        ))}

        {/* Add Aspect Button */}
        <div className="flex">
          <button
            className="flex items-center justify-center border-[2px] border-gray-200 border-dashed rounded-[12px] bg-gray-50 flex justify-center text-gray-500 h-[60px] items-center w-full"
            onClick={handleAddAspect}
          >
            <PlusIcon width={18} className="mr-3" /> Add Aspect
          </button>
        </div>
        <hr className="my-6" />
        <div className="flex h-[40px] items-end justify-end ">
          {submitting ? (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type=""
              disabled={true}
            >
              <BtnSpinner />
            </button>
          ) : (
            <button
              className=" bg-main-violate w-[150px] sm:w-[150px] px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
              type="submit"
            >
              Save
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default PerformanceReviewForm;
