// store.js
import { configureStore } from "@reduxjs/toolkit";
import candidatesReducer from "./features/candidate/candidatesSlice";
import jobsReducer from "./features/jobs/jobsSlice";
import dashboardsReducer from "./features/dashboards/dashboardsSlice";
import adminQuestionTagCategoryReduces from "./features/admin/assessments/questionTagCategorySlice";
import userReducer from "./features/user/userSlice";
import notificationReducer from "./features/notification/notificationSlice";
import jobDepartmentReducer from "./features/shared/jobDepartmentSlice";
import jobPositionReducer from "./features/shared/jobPositionSlice";
import interviewReducer from "./features/interviews/interviewSlice";
import appraisalTemplateReducer from "./features/appraisal/appraisalTemplateSlice";
import appraisalReducer from "./features/appraisal/appraisalSlice";

const store = configureStore({
  reducer: {
    candidates: candidatesReducer,
    jobs: jobsReducer,
    interviews: interviewReducer,
    dashboards: dashboardsReducer,
    adminQuestionTagCategory: adminQuestionTagCategoryReduces,
    user: userReducer,
    notification: notificationReducer,
    jobDepartment: jobDepartmentReducer,
    jobPosition: jobPositionReducer,
    appraisalTemplate: appraisalTemplateReducer,
    appraisal: appraisalReducer,
  },
});

export default store;
