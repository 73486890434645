import axios from "axios";
import moment from "moment";

export const createTemplate = async (data) => {
  const response = await axios.post(`/api/client/appraisal-templates`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const updateTemplate = async (templateId, data) => {
  const response = await axios.put(`/api/client/appraisal-templates/${templateId}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const getAppraisalTemplates = async (queryString = "") => {
  const response = await axios.get(`/api/client/appraisal-templates?${queryString}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
export const getAppraisalTemplateDetail = async (id) => {
  const response = await axios.get(`/api/client/appraisal-templates/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
