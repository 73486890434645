import React from "react";
import { useState, useEffect } from "react";
import PersonalityRadarChart from "../../../../../Component/PersonalityRadarChart";
import Slider from "react-slick";
import EmptyState from "../../Components/EmptyState";
import StatsTable from "../../../../../Component/Tables/StatsTable";
import { Tooltip } from "chart.js";
import { normalizeToPercentage } from "../../../../../helpers";
const SoftSkillsOverview = ({ data }) => {
  useEffect(() => {
    // console.log("SOFT SKILLS DATA " , data)
  }, [data]);
  const StatusLabel = ({ value, max_value, custom_label = null }) => {
    const result_percentage = (value / max_value) * 100;
    if (result_percentage >= 0 && result_percentage <= 33) {
      return (
        <p className="text-label red text-sm mb-2">
          {custom_label ? custom_label : "Low"}
        </p>
      );
    } else if (result_percentage > 33 && result_percentage <= 66) {
      return (
        <p className="text-label yellow text-sm mb-2">
          {custom_label ? custom_label : "Medium"}
        </p>
      );
    } else if (result_percentage > 66) {
      return (
        <p className="text-label green text-sm mb-2">
          {custom_label ? custom_label : "High"}
        </p>
      );
    }
  };
  const TableContent = ({ content = [] }) => {
    let total_row = 0;
    const table_content = content.map((item, index) => {
      if (item.result.length <= 0) {
        return <></>;
      } else {
        let total_max_value = 0;
        let total_min_value = 0;
        let total_result = 0;
        let total_ranking_percentage = 0;
        let total_ranking_position = 0;
        let benchmark_result = 0;
        let result_percentage = 0;
        let benchmark_percentage = 0;
        item.result.forEach((element) => {
          let temp_ranking_percentage = 0;
          let temp_ranking_position = 0;
          element.data.forEach((tag) => {
            total_max_value += parseFloat(tag.max_value);
            total_min_value += parseFloat(tag.min_value);
            total_result += parseFloat(tag.result);
            benchmark_result += parseFloat(tag.benchmark);
            temp_ranking_percentage += parseFloat(tag.ranking_percentage);
            temp_ranking_position += tag.ranking_position;
          });
          total_ranking_percentage +=
            temp_ranking_percentage / element.data.length;
          total_ranking_position += temp_ranking_position / element.data.length;
        });
        // result_percentage = ((total_result / total_max_value) * 100).toFixed(0)
        result_percentage = normalizeToPercentage(
          total_result,
          total_min_value,
          total_max_value
        );
        // benchmark_percentage = ((benchmark_result / total_max_value) * 100).toFixed(0)
        benchmark_percentage = normalizeToPercentage(
          benchmark_result,
          total_min_value,
          benchmark_result > total_max_value
            ? benchmark_result
            : total_max_value
        );
        total_row += 1;
        total_ranking_percentage = total_ranking_percentage.toFixed(0);
        total_ranking_position = total_ranking_position.toFixed(0);
        return (
          <>
            <tr className="whitespace-nowrap  bg-gray-50 rounded-lg">
              <td className="px-6 py-4 text-sm text-gray-900 mdb:max-w-[100px] font-semibold mdb:w-[20%]">
                {item.test_name}
              </td>
              <td className="py-4 pr-6 mdb:pr-0 text-sm text-gray-500 mdb:max-w-[90px] mdb:w-[15%]">
                <StatusLabel
                  value={item.total_result}
                  max_value={item.total_max_value}
                  custom_label={
                    item.custom_result_label ? item.total_result_label : null
                  }
                />
                <p className="text-green-500 text-sm mb-2 font-bold">
                  Top{" "}
                  {total_ranking_position <= 10
                    ? total_ranking_position
                    : total_ranking_percentage + "%"}
                </p>
              </td>
              <td className="py-4 pr-6 text-sm text-gray-500 flex flex-col">
                <p className="text-lg mb-1 text-gray-700">
                  <b>{result_percentage}%</b>{" "}
                </p>
                <p>
                  Benchmark <b>{benchmark_percentage}%</b>{" "}
                </p>
              </td>

              <td className="py-4 text-sm text-gray-500 w-[50%] pr-6">
                {(() => {
                  if (result_percentage >= 0 && result_percentage <= 33) {
                    return (
                      <>
                        <div className="w-full h-[12px]  bg-gray-100 ">
                          <div
                            className="h-full text-center text-xs text-white bg-red-500 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>

                        <div className="w-full h-[12px]  bg-gray-100">
                          <div
                            className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>
                      </>
                    );
                  } else if (
                    result_percentage >= 33 &&
                    result_percentage <= 66
                  ) {
                    return (
                      <>
                        <div className="w-full h-[12px]  bg-gray-100">
                          <div
                            className="h-full text-center text-xs text-white bg-yellow-300 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>

                        <div className="w-full h-[12px]  bg-gray-100">
                          <div
                            className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>
                      </>
                    );
                  } else if (result_percentage > 66) {
                    return (
                      <>
                        <div className="w-full h-[12px]  bg-gray-100">
                          <div
                            className="h-full text-center text-xs text-white bg-green-500 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>

                        <div className="w-full h-[12px]  bg-gray-100">
                          <div
                            className="h-full text-center text-xs text-white bg-gray-200 rounded-r-sm"
                            style={{
                              width: `${result_percentage}%`,
                            }}
                          ></div>
                        </div>
                      </>
                    );
                  }
                })()}
              </td>
            </tr>
            <tr className="h-[16px]"></tr>
          </>
        );
      }
    });
    if (total_row <= 0) {
      if (content.filter((item) => item.is_choosen).length <= 0) {
        return (
          <>
            <td colSpan={4}>
              <EmptyState text="No test has been chosen for this assessment category" />
            </td>
          </>
        );
      } else {
        return (
          <>
            <td colSpan={4}>
              <EmptyState />
            </td>
          </>
        );
      }
    }
    return table_content;
  };
  return (
    <div className="card flex flex-col lg:flex-row justify-between w-full mb-5 anti-cheat">
      <div className="content w-full">
        <div className="flex flex-col h-[100%]">
          <p className="text-lg text-[#374151] font-semibold mb-4">
            Soft Skills
          </p>
          <StatsTable
            checkbox={false}
            headers={["Test", "Category", "Score", "Overall"]}
            content={<TableContent content={data} />}
          />
        </div>
      </div>
    </div>
  );
};

export default SoftSkillsOverview;
