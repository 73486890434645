import SimpleTable from "../../../../../Component/Tables/SimpleTable";
import DropdownDots from "../../../../../Component/Tables/DropdownDots";
import { Dropdown } from "flowbite-react";
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import PopUp from "../../../../../Component/PopUp";
import DetailTestPreview from "../../../../../Component/DetailTestPreview";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import "moment/locale/id";
import { getAppraisalDetail } from "../../../../../api";
import PageSpinner from "../../../../../Component/PageSpinner";

const AppraisalDataDetail = ({ id }) => {
  const [appraisal, setAppraisal] = useState({});
  const [loading, setLoading] = useState(true);
  const fetchAppraisalDetail = async (appraisalId) => {
    setLoading(true);
    const result = await getAppraisalDetail(appraisalId);
    setLoading(false);
    if (result.success) {
      setAppraisal(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  useEffect(() => {
    if (id) {
      fetchAppraisalDetail(id);
    }
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="mb-6">
        {loading && <PageSpinner />}
        {!loading && (
          <div className="grid-cols-1 md:grid-cols-2 grid gap-4 ">
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                Name / Goal
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Type your job role..."
                value={appraisal.name}
                disabled={true}
              />
            </div>
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                Assessor
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Type your job role..."
                value={
                  appraisal.assessor_client
                    ? appraisal.assessor_client.name
                    : ""
                }
                disabled={true}
              />
            </div>
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                Job Role
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="title"
                id="title"
                placeholder="Type your job role..."
                value={
                  appraisal.company_job_position
                    ? appraisal.company_job_position.name
                    : ""
                }
                disabled={true}
              />
            </div>
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                Start Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="start_date"
                id="start_date"
                placeholder="Choose date..."
                value={appraisal.start_date}
                disabled={true}
              />
            </div>
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                End Date
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="date"
                name="end_date"
                id="end_date"
                placeholder="Choose date..."
                value={appraisal.end_date}
                disabled={true}
              />
            </div>
            <div className="mb-2">
              <label className=" text-gray-800 text-sm font-bold leading-[140%]">
                Template
              </label>
              <input
                className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                type="text"
                name="template"
                id="template"
                placeholder="Choose date..."
                value={
                  appraisal.appraisal_template
                    ? appraisal.appraisal_template.name
                    : ""
                }
                disabled={true}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AppraisalDataDetail;
