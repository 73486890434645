import React, { useState } from "react";
import { useEffect } from "react";
import StatsTable from "../../../../../Component/Tables/StatsTable";
import Tooltip from "../../../../../Component/Tooltip";
import EmptyState from "./EmptyState";
import { normalizeToPercentage } from "../../../../../helpers";
import { Avatar } from "flowbite-react";
import StatsTable2 from "../../../../../Component/Tables/StatsTable2";
const ComparisonGantChart = ({ data = [] }) => {
  const colorRanges = [
    {
      bgClass: "bg-blue-purple-gradient",
    },
    {
      bgClass: "bg-red-orange-gradient",
    },
    {
      bgClass: "bg-green-yellow-gradient",
    },
    {
      bgClass: "bg-orange-yellow-gradient",
    },
    {
      bgClass: "bg-blue-green-gradient",
    },
  ];

  const [softSkills, setSoftSkills] = useState([]);
  const remappedData = () => {
    const remapped = {};
    data.forEach((item) => {
      item.result.forEach((test) => {
        if (!remapped[test.test]) {
          remapped[test.test] = {
            test: test.test,
            test_name: test.test_name,
            candidates: [],
          };
        }

        remapped[test.test].candidates.push({
          name: item.candidate.fullname,
          avatar: item.candidate.avatar,
          result: test.result,
          total_tag_test: test.total_tag_test,
          total_max_value: test.total_max_value,
          total_min_value: test.total_min_value,
          total_result: test.total_result,
          custom_result_label: test.custom_result_label,
          is_choosen: test.is_choosen,
        });
      });
    });
    const remappedArray = Object.values(remapped);
    setSoftSkills(remappedArray);
  };

  useEffect(() => {
    remappedData();
  }, [data]);
  const TooltipContent = ({ overall = 0, average = 0 }) => {
    return (
      <>
        <div className="flex items-center mr-4">
          {overall >= 0 && overall <= 33 && (
            <>
              <span className="bg-red-500 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          {overall > 33 && overall <= 66 && (
            <>
              <span className="bg-yellow-300 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          {overall > 66 && (
            <>
              <span className="bg-green-500 h-[8px] w-[8px] rounded-full mr-2"></span>
            </>
          )}
          <p className="font-semibold">
            Overall: <b>{overall}%</b>
          </p>
        </div>
        <div className="flex items-center mr-4">
          <span className="bg-orange-secondary-600 h-[8px] w-[8px] rounded-full mr-2"></span>
          <p className="font-semibold">
            Benchmark: <b>{average}%</b>
          </p>
        </div>
      </>
    );
  };
  const StatusLabel = ({ value, max_value, custom_label = null }) => {
    const result_percentage = (value / max_value) * 100;
    if (result_percentage >= 0 && result_percentage <= 33) {
      return (
        <p className="text-label red text-sm mb-2">
          {custom_label ? custom_label : "Low"}
        </p>
      );
    } else if (result_percentage > 33 && result_percentage <= 66) {
      return (
        <p className="text-label yellow text-sm mb-2">
          {custom_label ? custom_label : "Medium"}
        </p>
      );
    } else if (result_percentage > 66) {
      return (
        <p className="text-label green text-sm mb-2">
          {custom_label ? custom_label : "High"}
        </p>
      );
    }
  };
  const TableContent = ({ content = [] }) => {
    let total_row = 0;
    const table_content = content.map((item, index) => {
      if (item.result.length <= 0) {
        return <></>;
      } else {
        let total_max_value = 0;
        let total_min_value = 0;
        let total_result = 0;
        let total_ranking_percentage = 0;
        let total_ranking_position = 0;
        let benchmark_result = 0;
        let result_percentage = 0;
        let benchmark_percentage = 0;
        item.result.forEach((element) => {
          let temp_ranking_percentage = 0;
          let temp_ranking_position = 0;
          element.data.forEach((tag) => {
            total_max_value += parseFloat(tag.max_value);
            total_min_value += parseFloat(tag.min_value);
            total_result += parseFloat(tag.result);
            benchmark_result += parseFloat(tag.benchmark);
            temp_ranking_percentage += parseFloat(tag.ranking_percentage);
            temp_ranking_position += tag.ranking_position;
          });
          total_ranking_percentage +=
            temp_ranking_percentage / element.data.length;
          total_ranking_position += temp_ranking_position / element.data.length;
        });
        result_percentage = normalizeToPercentage(
          total_result,
          total_min_value,
          total_max_value
        );
        benchmark_percentage = normalizeToPercentage(
          benchmark_result,
          total_min_value,
          benchmark_result > total_max_value
            ? benchmark_result
            : total_max_value
        );
        total_row += 1;
        total_ranking_percentage = total_ranking_percentage.toFixed(0);
        total_ranking_position = total_ranking_position.toFixed(0);
        return (
          <>
            <tr className="whitespace-nowrap  bg-gray-50 rounded-lg">
              <td className="px-6 py-3 text-sm text-gray-900 mdb:max-w-[50px] font-semibold w-[5%]">
                <Avatar
                  className="hidden mdb:block"
                  alt="User settings"
                  img={item.avatar}
                  rounded={true}
                />
              </td>
              <td className="py-4 text-sm text-gray-900 mdb:max-w-[100px] font-semibold mdb:w-[20%] truncate">
                {item.name}
              </td>
              {/* <td className="py-4 pr-6 mdb:pr-0 text-sm text-gray-500 mdb:max-w-[90px] mdb:w-[15%]">
                <StatusLabel
                  value={total_result - total_min_value}
                  max_value={total_max_value - total_min_value}
                  custom_label={
                    item.custom_result_label ? item.total_result_label : null
                  }
                />
              </td> */}
              {/* <td className="py-4 pr-6 text-sm text-gray-500 flex flex-col">
                <p className="text-lg mb-1 text-gray-700">
                  <b>{result_percentage}%</b>{" "}
                </p>
              </td> */}

              <td className="py-4 pl-3 pr-3 text-sm text-gray-500 mdb:w-[50%]">
                <div className="flex flex-col items-center justify-center">
                  <Tooltip
                    type="custom"
                    top="-58px"
                    element={
                      <TooltipContent
                        overall={result_percentage}
                        average={benchmark_percentage}
                      />
                    }
                  >
                    <div className="flex flex-col">
                      {/* <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                      <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`bg-gray-300 h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${benchmark_percentage}%`,
                          }}
                        ></div>
                      </div> */}
                      <div className="w-full h-[12px] bg-gray-100 relative">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                        <div
                          className={`h-[21px] text-center text-xs text-white border-r-[3px] border-[#605F5E] absolute top-[-4.5px]`}
                          style={{
                            width: `${benchmark_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Tooltip>
                  {/* <Tooltip
                    type="custom"
                    top="-58px"
                    element={<TooltipContent overall={benchmark_percentage} />}
                  ></Tooltip> */}
                </div>
                {/* {result_percentage >= 0 && result_percentage <= 33 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px] bg-gray-100 ">
                        <div
                          className={`${colorRanges[index].bgClass} h-full text-center text-xs text-white rounded-r-sm`}
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )}
                {result_percentage > 33 && result_percentage <= 66 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px]  bg-gray-100">
                        <div
                          className="h-full text-center text-xs text-white bg-blue-purple-gradient rounded-r-sm"
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )}
                {result_percentage > 66 && (
                  <div className="flex flex-col items-center justify-center">
                    {result_percentage}%
                    <Tooltip
                      type="custom"
                      top="-58px"
                      element={<TooltipContent overall={result_percentage} />}
                    >
                      <div className="w-full h-[12px]  bg-gray-100">
                        <div
                          className="h-full text-center text-xs text-white bg-green-500 rounded-r-sm"
                          style={{
                            width: `${result_percentage}%`,
                          }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                )} */}
              </td>
              <td className="text-sm text-gray-900 mdb:max-w-[100px] font-medium pb-1">
                {result_percentage}%
              </td>
            </tr>
            <tr className="h-[8px]"></tr>
          </>
        );
      }
    });
    if (total_row <= 0) {
      if (content.filter((item) => item.is_choosen).length <= 0) {
        return (
          <>
            <td colSpan={4}>
              <EmptyState text="No test has been chosen for this assessment category" />
            </td>
          </>
        );
      } else {
        return (
          <>
            <td colSpan={4}>
              <EmptyState />
            </td>
          </>
        );
      }
    }
    return table_content;
  };
  return (
    <div className="grid grid-col-1 xl:grid-cols-2 4xl:grid-cols-3 gap-2">
      {softSkills.map((item, index) => (
        <div className="card-mini mdb:card flex flex-col lg:flex-row justify-between w-full anti-cheat mdb:mt-0">
          <div className="content w-full">
            <div className="flex flex-col h-[100%]">
              <p className="mdb:text-lg text-[#374151] font-semibold mb-1">
                {item.test_name}
              </p>

              <StatsTable2
                checkbox={false}
                headers={["Result", "", "", ""]}
                content={<TableContent content={item.candidates} />}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ComparisonGantChart;
