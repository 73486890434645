import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import {
  AcademicCapIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  CloudArrowUpIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/solid";
import SimpleTable from "../../../Component/Tables/SimpleTable";
import moment from "moment";
import axios from "axios";
import PageSpinner from "../../../Component/PageSpinner";
import TableSpinner from "../../../Component/TableSpinner";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  DocumentArrowDownIcon,
  ListBulletIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import BtnSpinner from "../../../Component/BtnSpinner";
import PopUp from "../../../Component/PopUp";
import BreadCrumb from "../../../Component/BreadCrumb";
import { documentTitle, rupiahFormat } from "../../../helpers";
import CheckSpam from "./Popup/CheckSpam";
import { useSelector } from "react-redux";
import MiniForbidden from "../../MiniForbidden";
import CandidateInJobList from "./CandidateInJobList";

const DetailPage = () => {
  let navigate = useNavigate();
  const permission = useSelector((state) => state.user.permissions);
  const { id } = useParams();
  const [user] = useState(() => {
    return JSON.parse(localStorage.getItem("user")) || null;
  });

  const [candidateView, setCandidateView] = useState("table");
  const [tableLoading, setTableLoading] = useState(1);
  const [loading, setLoading] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [candidateListFull, setCandidateListFull] = useState(false);
  const [bulkMode, setBulkMode] = useState(true);
  const [inviting, setInviting] = useState(false);
  const [file, setFile] = useState(null);
  const [activeImport, setActiveImport] = useState(null);
  const [activeImportLoading, setActiveImportLoading] = useState(true);
  const [manualInviteForm, setManualInviteForm] = useState({
    candidate_id: [],
    company_job_position_id: null,
  });
  const [position, setPosition] = useState({
    id: null,
    name: "",
    company_id: null,
    total_candidate_started: 0,
    total_candidate_finished: 0,
    total_candidate: 0,
    days_to_expiry: "",
    tags: [],
    is_publish: "",
    created_at: "",
    updated_at: "",
  });

  const [showCheckSpam, setShowCheckSpam] = useState(false);
  const [tests, setTests] = useState([]);
  const [otherCandidates, setOtherCandidates] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [refreshCandidate, setRefreshCandidate] = useState(false);

  const upload = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file.target.files[0]);
    await axios
      .post(`/api/client/candidate/invitation/${id}/import`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });
          getActiveImport();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const clearResume = (e) => {
    e.preventDefault();
    setFile(null);
  };

  const getPosition = async () => {
    setTableLoading(true);
    setLoading(true);
    await axios
      .get(`/api/client/assessment/job-position/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setPosition(result.data.position);
          setTests(result.data.tests);
          setTableLoading(false);
          setLoading(false);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleDownload = async () => {
    setDownloading(true);
    await axios
      .get(`/api/client/candidate/invitation/${id}/import?download=template`, {
        responseType: "blob",
      })
      .then((response) => {
        // Create a Blob URL from the response
        const blob = new Blob([response.data]);
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element and simulate a click to trigger download
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `Candidate Invitation ${position.job_role}.xlsx`;
        link.click();

        // Clean up the Blob URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const getActiveImport = async () => {
    setActiveImportLoading(true);
    await axios
      .get(`/api/client/candidate/invitation/${id}/import`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setActiveImport(result.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setActiveImportLoading(false);
      });
  };

  const getOtherCandidates = async () => {
    await axios
      .get(`/api/client/assessment/job-position/${id}/other-candidates`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          setOtherCandidates(result.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const inviteCandidate = async (e) => {
    e.preventDefault();
    setInviting(true);
    const formatForm = {
      candidate_ids: manualInviteForm.candidate_id.map((item) => item.value),
      company_job_position_id: position.id,
    };

    await axios
      .post(`/api/client/candidate/invitation`, JSON.stringify(formatForm), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const result = response.data;
        if (result.success) {
          toast.success("Invite candidate success", {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });
          // getCandidateInPosition();
        } else {
          toast.error(result.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      });
    setInviting(false);
    setRefreshCandidate(true);
  };

  useEffect(() => {
    documentTitle("Detail Jobs");
    getPosition();
    getActiveImport();
    getOtherCandidates();
  }, []);

  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={"100%"} />;
    } else {
      return (
        <>
          {tests.map((value, index) => {
            return (
              <tr className="whitespace-nowrap border-b-[1px]">
                {/* <td class="px-6 py-4 text-sm text-gray-500">
                  <input
                    class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer"
                    type="checkbox"
                  />
                </td> */}
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">
                    {itemsPerPage * (currentPage - 1) + index + 1}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500">{value.name}</div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {value.assessment.name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {value.question_type_label}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {value.total_question}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {moment.duration(value.times, "seconds").minutes() +
                    " Minutes, " +
                    moment.duration(value.times, "seconds").seconds() +
                    " Seconds"}
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  // check if logged in
  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    }
  });
  useEffect(() => {
    if(refreshCandidate){
      setTimeout(() => {
        setRefreshCandidate(false);
      }, 1000);
    }
  }, [refreshCandidate]);

  const myStyle = {
    background: `#F8F8F8`,
    minHeight: "calc(100vh - 80px)",
    marginTop: "-10px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle} className="px-[10px] md:px-[44px] py-[24px]">
      <ToastContainer />
      {showCheckSpam && (
        <PopUp>
          <CheckSpam
            onClose={() => setShowCheckSpam(false)}
            onConfirm={(e) => setShowCheckSpam(false)}
          />
        </PopUp>
      )}
      <div className="card flex w-full mb-6">
        <div className="bg-main-violate-50 w-[65px] h-[65px] flex justify-center items-center rounded-lg mr-6">
          <BriefcaseIcon className="w-[30px] text-main-violate" />
        </div>
        <div className="flex flex-col justify-around">
          <p className="text-xl text-grey-600 font-bold">
            Detail Job {position.job_role}
          </p>
          <small></small>
          <BreadCrumb
            list={[
              { href: "/client/jobs", title: "Jobs" },
              { href: null, title: `${position.id}` },
            ]}
          />
        </div>
      </div>

      <div className="summary-stats grid-cols-1 lg:grid-cols-3 grid gap-4 mb-6">
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          {loading ? (
            <PageSpinner />
          ) : (
            <>
              <div className="dashboard finished-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <ClipboardDocumentListIcon width={32} color={"#F5F5F5"} />
              </div>
              {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1">Ongoing Test</p>
                <div className="flex">
                  <p className="text-xl mr-1">
                    {position.total_candidate_started}
                  </p>
                  <small className="relative top-[12px]">
                    from {position.total_candidate} candidates
                  </small>
                </div>

                {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
              </div>
            </>
          )}
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          {loading ? (
            <PageSpinner />
          ) : (
            <>
              <div className="dashboard profile-percentage h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <AcademicCapIcon width={32} color={"#F5F5F5"} />
              </div>
              {/* <img src={dummy1} alt='dummy' className='mr-6' /> */}
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1">Candidate Finished</p>
                <div className="flex">
                  <p className="text-xl mr-1">
                    {position.total_candidate_finished}
                  </p>
                  <small className="relative top-[12px]">
                    from {position.total_candidate} candidates
                  </small>
                </div>

                {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
              </div>
            </>
          )}
        </div>
        <div className="card flex w-full bg-white px-6 py-6 rounded-[12px]">
          {loading ? (
            <PageSpinner />
          ) : (
            <>
              <div className="dashboard total-test h-[86px] w-[86px] mr-6 rounded flex items-center justify-center">
                <ClockIcon width={32} color={"#F5F5F5"} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-grey-600 mb-1">Deadline Test</p>
                <div className="flex">
                  <p className="text-lg mr-1 text-grey-600">
                    {moment(position.days_to_expiry).format("DD MMM YYYY")}
                  </p>
                  {/* <small className="relative top-[12px]"></small> */}
                </div>

                {/* <ArrowDownCircleIcon className='text-grey-600 w-[16px]' /> */}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="position-list mb-6">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-8">
            <div className="flex flex-col">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Detail Test
              </p>
              {/* <p className='text-base text-grey-700 font-light mb-5'>Complete your company profile</p> */}
            </div>
          </div>
          <div className="border-b border-gray-200 shadow">
            <SimpleTable
              checkbox={false}
              headers={[
                "NO",
                "TEST",
                "ASSESSMENT",
                "QUESTION TYPE",
                "ITEMS",
                "TIME",
              ]}
              content={<TableContent />}
            />
          </div>
        </div>
      </div>

      <div className="invite-section mb-6">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-2">
            <div>
              <p className="text-lg text-grey-600 font-bold mb-2">
                Invite Candidate
              </p>
              <p className="text-base text-grey-400 font-light">
                Invite your candidate to work on the questions.
              </p>
            </div>
            <button
              className="flex items-center gap-2 border border-main-violate hover:bg-purple-200 px-4 text-[16px] leading-[22.4px] text-main-violate font-semibold h-[36px] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
              type=""
              disabled={downloading}
              onClick={(e) => handleDownload()}
            >
              {downloading ? (
                <BtnSpinner />
              ) : (
                <>
                  Download Template{" "}
                  <DocumentArrowDownIcon className="w-[16px]" />
                </>
              )}
            </button>
          </div>
          {permission?.my_candidate?.includes("import") ? (
            <div>
              <div className="mb-5">
                <label className="text-gray-800 text-sm font-bold leading-[140%]">
                  Manual Input Mode
                </label>
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[7px] cursor-pointer"
                  type="checkbox"
                  checked={!bulkMode}
                  onChange={(e) => {
                    setBulkMode(!bulkMode);
                  }}
                />
              </div>
              {bulkMode ? (
                <>
                  {activeImportLoading ? (
                    <PageSpinner />
                  ) : (
                    <>
                      {activeImport ? (
                        <>
                          <div className="relative h-[120px] rounded-lg border border-dashed border-gray-200 bg-gray-50 flex justify-center items-center hover:outline-none hover:cursor-not-allowed">
                            <div className="absolute">
                              <div className="flex flex-col items-center ">
                                <NoSymbolIcon
                                  width={40}
                                  className="text-gray-400"
                                />
                                <span className="block text-soft-grey text-[14px] leading-[140%] mt-3 font-semibold">
                                  You have imported the file
                                </span>
                                <span className="block text-soft-grey text-[14px] leading-[140%] mt-1">
                                  Please go to preview table
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {file ? (
                            <>
                              <div className="relative h-[50px] rounded-lg border bg-white flex justify-between items-center hover:outline-none px-4">
                                <span className="block text-soft-grey text-[14px]">
                                  {file.target.files[0].name}
                                </span>
                                <button onClick={clearResume}>
                                  <svg
                                    className="h-5 w-5 text-gray-500"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    {" "}
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                    />{" "}
                                    <line x1="4" y1="7" x2="20" y2="7" />{" "}
                                    <line x1="10" y1="11" x2="10" y2="17" />{" "}
                                    <line x1="14" y1="11" x2="14" y2="17" />{" "}
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />{" "}
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                  </svg>
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="relative h-[120px] rounded-lg border border-dashed border-gray-200 bg-gray-50 flex justify-center items-center hover:outline-none ">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    {uploading ? (
                                      <>
                                        <BtnSpinner /> Uploading
                                      </>
                                    ) : (
                                      <>
                                        <CloudArrowUpIcon
                                          width={30}
                                          className="text-gray-400"
                                        />
                                        <span className="block text-soft-grey text-[14px] leading-[140%] mt-3 font-semibold">
                                          Click to upload
                                        </span>
                                        <span className="block text-soft-grey text-[14px] leading-[140%] mt-1">
                                          <b>.XLXS, .XLS</b>(Max. 100 Rows)
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0 cursor-pointer"
                                  name="uploadcv"
                                  onChange={(e) => setFile(e)}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <div className="flex justify-end">
                    {/* <button
                    class=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed mt-2"
                    onClick={(e) => navigate(`/client/jobs/detail/${id}/import-preview`)}
                  >
                    Preview Table

                  </button> */}
                    {activeImport ? (
                      <>
                        <button
                          className="ml-2 bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed mt-2"
                          onClick={(e) => {
                            navigate(
                              `/client/jobs/detail/${id}/import-preview/${activeImport.id}`
                            );
                          }}
                        >
                          {uploading ? <BtnSpinner /> : "Preview Table"}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="ml-2 bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed mt-2"
                          disabled={uploading || !file}
                          onClick={upload}
                        >
                          {uploading ? <BtnSpinner /> : "Upload"}
                        </button>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <form onSubmit={inviteCandidate}>
                    <div className="grid-cols-1 md:grid-cols-2 grid gap-4 mb-6">
                      <div className="mb-2">
                        <label className="text-gray-800 text-sm font-bold leading-[140%]">
                          Select Exisiting Candidates
                        </label>
                        <Select
                          isMulti={true}
                          className="py-0.5 rounded-lg mt-2 border border-line-grey"
                          classNamePrefix={"select"}
                          value={manualInviteForm.candidate_id}
                          options={otherCandidates.map((option) => {
                            return {
                              value: option.id,
                              label: option.fullname,
                            };
                          })}
                          onChange={(e) => {
                            setManualInviteForm({
                              ...manualInviteForm,
                              candidate_id: e,
                            });
                          }}
                        />
                        {/* <select
                          className="form-select appearance-none block w-full px-3  py-2 text-grey-600 text-[14px] bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-md mt-2 transition ease-in-out focus:outline-none focus:border-main-violate border border-line-grey"
                          aria-label="Default select example"
                          value={manualInviteForm.candidate_id}
                          onChange={(e) => {
                            setManualInviteForm({
                              ...manualInviteForm,
                              candidate_id: e.target.value,
                            });
                          }}
                        >
                          <option selected disabled value="">
                            Choose your candidate
                          </option>
                          {otherCandidates.map((candidate) => (
                            <option key={candidate.id} value={candidate.id}>
                              {candidate.fullname}
                            </option>
                          ))}
                        </select> */}
                      </div>
                      {/* <div className="mb-2">
                        <label className="text-gray-800 text-sm font-bold leading-[140%]">
                          Set Invitation Expired.
                        </label>
                        <input
                          className="border w-full p-3 h-10 placeholder:text-sm rounded-lg mt-2 focus:outline-none focus:border-main-violate border border-line-grey disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed"
                          type="number"
                          name="days"
                          id="days"
                          placeholder="Days to expired"
                          value={manualInviteForm.expired_at}
                          onChange={(e) => {
                            setManualInviteForm({
                              ...manualInviteForm,
                              expired_at: e.target.value,
                            });
                          }}
                          required
                        />
                      </div> */}
                    </div>
                    <button
                      className=" bg-main-violate px-4 text-[16px] leading-[22.4px] text-white font-semibold h-[40px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      type="submit"
                      disabled={inviting}
                    >
                      {inviting ? <BtnSpinner /> : "Apply"}
                    </button>
                  </form>
                </>
              )}
            </div>
          ) : (
            <MiniForbidden className="rounded-lg" />
          )}
        </div>
      </div>
      {candidateListFull && (
        <div
          className="popup-alert justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate z-[999]"
          style={{ background: "#7a727240" }}
        >
          <div className="relative w-auto my-6 mx-auto w-full px-4">
            <div className="candidate-list">
              <div className="card flex flex-col w-full">
                <div className="flex justify-between w-full mb-8">
                  <div className="flex justify-between w-full">
                    <p className="text-xl text-grey-600 font-bold mb-2">
                      Candidate List
                    </p>
                    <div className="flex gap-4">
                      {candidateView == "table" && (
                        <ListBulletIcon
                          className="w-[20px] cursor-pointer"
                          onClick={() => setCandidateView("card")}
                          title="Show as card"
                        />
                      )}
                      {candidateView == "card" && (
                        <TableCellsIcon
                          className="w-[20px] cursor-pointer"
                          onClick={() => setCandidateView("table")}
                          title="Show as table"
                        />
                      )}
                      {candidateListFull && (
                        <ArrowsPointingInIcon
                          className="w-[18px] cursor-pointer"
                          onClick={() => setCandidateListFull(false)}
                          title="Close full this table"
                        />
                      )}
                      {!candidateListFull && (
                        <ArrowsPointingOutIcon
                          className="w-[18px] cursor-pointer"
                          onClick={() => setCandidateListFull(true)}
                          title="Show full this table"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <CandidateInJobList id={id} view={candidateView} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="candidate-list">
        <div className="card flex flex-col w-full">
          <div className="flex justify-between w-full mb-8">
            <div className="flex justify-between w-full">
              <p className="text-xl text-grey-600 font-bold mb-2">
                Candidate List
              </p>
              <div className="flex gap-4">
                {candidateView == "table" && (
                  <ListBulletIcon
                    className="w-[20px] cursor-pointer"
                    onClick={() => setCandidateView("card")}
                    title="Show as card"
                  />
                )}
                {candidateView == "card" && (
                  <TableCellsIcon
                    className="w-[20px] cursor-pointer"
                    onClick={() => setCandidateView("table")}
                    title="Show as table"
                  />
                )}
                {candidateListFull && (
                  <ArrowsPointingInIcon
                    className="w-[18px] cursor-pointer"
                    onClick={() => setCandidateListFull(false)}
                    title="Close full this table"
                  />
                )}
                {!candidateListFull && (
                  <ArrowsPointingOutIcon
                    className="w-[18px] cursor-pointer"
                    onClick={() => setCandidateListFull(true)}
                    title="Show full this table"
                  />
                )}
              </div>
            </div>
          </div>
          <CandidateInJobList
            id={id}
            view={candidateView}
            position={position}
            refresh={refreshCandidate}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
